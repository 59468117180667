<template>
  <main>
    <section class="terms">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="terms-hdng center-align">昶联如何使用Cookie及类似技术</h1>

            <p class="terms-desc indent">
              本Cookie政策解释昶联（“昶联”，“我们”，“我们的”）如何在www.solutioncl.com（“网站”）和包含或引用此Cookie政策的所有产品和服务（合称“服务”）中使用Cookie及类似的技术。
            </p>

            <p class="terms-desc">
              就我们如何处理您的个人数据，请见《昶联隐私政策》。
            </p>

            <p class="terms-desc">（一）Cookie是什么？</p>

            <p class="terms-desc">
              Cookie是访问网站时放置在您的计算机或移动设备上的小型数据文件。Cookie
              的内容只能由创建它的服务器检索或读取。每个 Cookie
              对您的网络浏览器或移动应用程序都是唯一的。Cookie服务于不同的目的，例如帮助我们了解网站如何被使用，让您高效地浏览页面，记住您的偏好并总体上改善您的浏览体验。
              我们可能会同时使用会话Cookie（该等Cookie将在您关闭网络浏览器后失效）和永久Cookie（根据适用的法律法规，该等Cookie将在您的计算机或移动设备上保留六个月，或者直到您撤回同意或删除它们为止）。
            </p>

            <p class="terms-desc">我们使用三种类型的Cookie：</p>
            <p class="terms-desc">
              1. 严格必要的Cookie:
              用于登录和验证。Cookie将确保您对该网站的访问尽可能顺利；
            </p>
            <p class="terms-desc">
              2. 个性化Cookie：用于存储您的偏好和设置。我们借助
              Cookie来保存设置，如计算机或移动设备的语言和其他浏览偏好等；
            </p>
            <p class="terms-desc">
              3.
              统计分析Cookie：我们收集关于您使用服务的信息，包括您的单次或多次访问，这些Cookie帮助我们了解服务是如何运行、使用的。
            </p>
            <p class="terms-desc">
              许多服务需要Cookie才能运行。如果拟选择不允许与这些服务相关的Cookie，则您可能无法使用该等服务或该等服务的某些功能。
            </p>

            <p class="terms-desc">（二）其他类似的技术</p>
            <p class="terms-desc">
              除Cookie之外，我们可能会使用其他技术来自动收集信息。
            </p>
            <p class="terms-desc">
              如：浏览器网络存储。我们可能会使用浏览器网络存储，也称为本地存储对象，从而达到与Cookie类似的目的。浏览器Web存储可以存储比Cookie更多的数据量。您的网络浏览器可能提供清除浏览器网络存储的功能。
            </p>

            <p class="terms-desc">（三）请勿追踪（Do Not Track）</p>
            <p class="terms-desc">
              很多网络浏览器均提供 Do Not Track 功能，该功能可向网站发布 Do Not
              Track
              请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何处理此类请求。
              如果您启用了“请勿追踪”或您的浏览器可能提供的其他类似功能，昶联将不会更改本声明中所述的收集和使用您数据的方式。但是，我们保留对您的“请勿追踪”请求做出回应并停止收集您的数据而不另行通知的权利。
            </p>

            <p class="terms-desc">（四）您的选择</p>
            <p class="terms-desc">
              大多数浏览器允许您删除或拒绝Cookie。为此，请按照浏览器设置中的说明进行操作。默认情况下，许多浏览器接受Cookie，直到您更改设置为止。
            </p>
            <p class="terms-desc">
              如果您不接受我们的Cookie，您在使用本网站时可能会遇到一些不便。例如，我们可能无法识别您的计算机或移动设备，并且您可能需要在每次访问网站时登录。
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
  
<script>
export default {
  name: "ZhCookiePolicy",
};
</script>
<style scoped>
.article-Registration {
  padding-left: 24px;
}
.center-align {
  display: block;
  text-align: center;
}
:deep(strong > p) {
  font-weight: bold !important;
}
.indent {
  text-indent: 2ch;
}
.right-align {
  display: block;
  text-align: right;
}
</style>