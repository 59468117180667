<template>
  <!-- header start -->
  <header class="desktop">
    <div class="container">
      <div class="header_inner_wrap">
        <div class="inner">
          <router-link to="/zh" class="logo">
            <img src="../assets/images/logo.svg" alt="NXCLOUD" />
          </router-link>
          <div class="navigation_wrap">
            <div class="inner">
              <nav>
                <ul>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="#">产品中心</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap" :class="innerheight <= 750 ? 'submenuScroll':''">
                      <ul>
                        <li>
                          <a href="#" class="hdng"> 沟通 </a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/zh-product-message">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/message_icon.svg"
                                        alt="chat"
                                      />
                                    </i>
                                    <span class="text">消息</span>
                                  </router-link>
                                </div>

                                <router-link
                                  :to="{
                                    path: '/zh-product-message',
                                    query: { id: 'smsOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="smsOtp"
                                    data-url="../zh-product-message"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_01.svg"
                                        class="tm_hdr_icon"
                                      />
                                      国际短信验证码
                                    </h4>
                                    <p>
                                      通过短信发送随机数字验证码
                                    </p>
                                  </div>
                                </router-link>

                                <router-link
                                  :to="{
                                    path: '/zh-product-message',
                                    query: { id: 'marketingOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="marketingOtp"
                                    data-url="../zh-product-message"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_02.svg"
                                        class="tm_hdr_icon"
                                      />
                                      国际营销短信
                                    </h4>
                                    <p>
                                      通过短信进行企业宣传推广
                                    </p>
                                  </div>
                                </router-link>

                                <router-link
                                  :to="{
                                    path: '/zh-product-message',
                                    query: { id: 'notificationOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="notificationOtp"
                                    data-url="../zh-product-message"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_09.svg"
                                        class="tm_hdr_icon"
                                      />
                                      国际通知短信
                                    </h4>
                                    <p>
                                      向客户发送精准的短信通知
                                    </p>
                                  </div>
                                </router-link>

                                <router-link
                                  :to="{
                                    path: '/zh-product-message',
                                    query: { id: 'emailOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="emailOtp"
                                    data-url="../zh-product-message"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_05.svg"
                                        class="tm_hdr_icon"
                                      />
                                      邮件验证码
                                    </h4>
                                    <p>
                                      通过电子邮件发送验证码消息
                                    </p>
                                  </div>
                                </router-link>
                              </div>
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/zh-product-voice">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/voice_icon.svg"
                                        alt="Voices"
                                      />
                                    </i>
                                    <span class="text">语音</span>
                                  </router-link>
                                </div>

                                <router-link
                                  :to="{
                                    path: '/zh-product-voice',
                                    query: { id: 'voiceOtp' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="voiceOtp"
                                    data-url="../zh-product-voice"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_06.svg"
                                        class="tm_hdr_icon"
                                      />
                                      语音验证码
                                    </h4>
                                    <p>
                                      通过语音电话播报数字验证码
                                    </p>
                                  </div>
                                </router-link>

                                <router-link
                                  :to="{
                                    path: '/zh-product-voice',
                                    query: { id: 'voiceNotification' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="voiceNotification"
                                    data-url="../zh-product-voice"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_03.svg"
                                        class="tm_hdr_icon"
                                      />
                                      语音通知
                                    </h4>
                                    <p>
                                      通过语音向用户传递通知信息
                                    </p>
                                  </div>
                                </router-link>

                                <router-link
                                  :to="{
                                    path: '/zh-product-voice',
                                    query: { id: 'sipTrunk' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="sipTrunk"
                                    data-url="../zh-product-voice"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_07.svg"
                                        class="tm_hdr_icon"
                                      />
                                      SIP 中继
                                    </h4>
                                    <p>
                                      基于会话发起协议的IP电话
                                    </p>
                                  </div>
                                </router-link>
                              </div>
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/zh-product-conversation">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/conversation_icon.svg"
                                        alt="Conversation"
                                      />
                                    </i>
                                    <span class="text">社媒对话</span>
                                  </router-link>
                                </div>
                                <router-link
                                  :to="{
                                    path: '/zh-product-conversation',
                                    query: { id: 'superMessaging' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="superMessaging"
                                    data-url="../zh-product-conversation"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_08.svg"
                                        class="tm_hdr_icon"
                                      />
                                      Super Messaging API
                                    </h4>
                                    <p>
                                      一站式集成全渠道对话API
                                    </p>
                                  </div>
                                </router-link>
                                <div class="businesses">
                                  <ul>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-conversation',
                                          query: { id: 'whatsappBusiness' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="whatsappBusiness"
                                          data-url="../zh-product-conversation"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/whatsapp_clr_icon.svg"
                                              class="tm_hdr_icon"
                                              alt="whatsapp"
                                            />
                                            WhatsApp Business
                                          </h4>
                                          <p>高效触达全球25亿用户</p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-conversation',
                                          query: { id: 'viberBusiness' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="viberBusiness"
                                          data-url="../zh-product-conversation"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/viber_clr_icon.svg"
                                              class="tm_hdr_icon"
                                              alt="viber"
                                            />
                                            Viber Business
                                          </h4>
                                          <p>提供商业化客户即时通信服务</p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-conversation',
                                          query: { id: 'zaloBusiness' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="zaloBusiness"
                                          data-url="../zh-product-conversation"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/zalo_clr_icon.svg"
                                              class="tm_hdr_icon"
                                              alt="zalo"
                                            />
                                            Zalo Business
                                          </h4>
                                          <p>精准触达超90%越南人口</p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="#" class="hdng"> 应用 </a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link
                                    to="/zh-product-fraud-prevention"
                                  >
                                    <i class="icon">
                                      <img
                                        src="../assets/images/fraud_icon.svg"
                                        alt="fraud"
                                      />
                                    </i>
                                    <span class="text">防欺诈策略</span>
                                  </router-link>
                                  <p class="desc">
                                    防止欺诈活动，确保业务安全
                                  </p>
                                </div>
                                <router-link
                                  :to="{
                                    path: '/zh-product-fraud-prevention',
                                    query: { id: 'FraudProtector' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="FraudProtector"
                                    data-url="../zh-product-fraud-prevention"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/Fraud_Protector.svg"
                                        class="tm_hdr_icon"
                                      />
                                      防欺诈管理
                                    </h4>
                                    <p>实现防欺诈和风险识别管理</p>
                                  </div>
                                </router-link>
                                <router-link
                                  :to="{
                                    path: '/zh-product-fraud-prevention',
                                    query: { id: 'numberCheck' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="numberCheck"
                                    data-url="../zh-product-fraud-prevention"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_11.svg"
                                        class="tm_hdr_icon"
                                      />
                                      号码检测
                                    </h4>
                                    <p>
                                      批量筛选和检测用户号码
                                    </p>
                                  </div>
                                </router-link>

                                <router-link
                                  :to="{
                                    path: '/zh-product-fraud-prevention',
                                    query: { id: 'OTA' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="OTA"
                                    data-url="../zh-product-fraud-prevention"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_12.svg"
                                        class="tm_hdr_icon"
                                      />
                                      一键登录（OTA）
                                    </h4>
                                    <p>一键登录，摆脱繁琐的流程</p>
                                  </div>
                                </router-link>
                              </div>
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link to="/zh-product-global-number">
                                    <i class="icon">
                                      <img
                                        src="../assets/images/hash_icon.svg"
                                        alt="global"
                                      />
                                    </i>
                                    <span class="text">全球号码</span>
                                  </router-link>
                                  <p class="desc">
                                    在任何地方呼叫和接听各国电话
                                  </p>
                                </div>

                                <router-link
                                  :to="{
                                    path: '/zh-product-global-number',
                                    query: { id: 'privacyNumber' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="privacyNumber"
                                    data-url="../zh-product-global-number"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_10.svg"
                                        class="tm_hdr_icon"
                                      />
                                      隐私号码
                                    </h4>
                                    <p>
                                      保护用户隐私不透露真实号码<br/>
                                    </p>
                                  </div>
                                </router-link>

                                <router-link
                                  :to="{
                                    path: '/zh-product-global-number',
                                    query: { id: 'globalNumber' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="globalNumber"
                                    data-url="../zh-product-global-number"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_13.svg"
                                        class="tm_hdr_icon"
                                      />
                                      SMS A2P, P2A
                                    </h4>
                                    <p>
                                      通过本地号码发送上下行短信
                                    </p>
                                  </div>
                                </router-link>
                              </div>
                              <div class="col">
                                <div class="icon_wrap">
                                  <router-link
                                    to="/zh-product-customer-engagement"
                                  >
                                    <i class="icon">
                                      <img
                                        src="../assets/images/support_icon.svg"
                                        alt="customer_engaging"
                                      />
                                    </i>
                                    <span class="text">客户互动</span>
                                  </router-link>
                                  <p class="desc">
                                    全渠道客户互动，提升营销转化
                                  </p>
                                </div>
                                <router-link
                                  :to="{
                                    path: '/zh-product-customer-engagement',
                                    query: { id: 'NXLink' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="NXLink"
                                    data-url="../zh-product-customer-engagement"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_14.svg"
                                        class="tm_hdr_icon"
                                      />
                                      NXLink
                                    </h4>
                                    <p>
                                      - 提供高效的海外数字营销服务<br/><span style="margin-top:5px">- 快速搭建海外智能呼叫中心</span>
                                    </p>
                                  </div>
                                </router-link>

                                <!-- <router-link
                                  :to="{
                                    path: '/zh-product-customer-engagement',
                                    query: { id: 'AICC' },
                                  }"
                                >
                                  <div
                                    class="text_wrap tabClickLink"
                                    data-tabclick="AICC"
                                    data-url="../zh-product-customer-engagement"
                                  >
                                    <h4>
                                      <img
                                        src="../assets/images/hdr_icon_15.svg"
                                        class="tm_hdr_icon"
                                      />
                                      AICC
                                    </h4>
                                    <p>
                                      一站式SaaS通讯平台，快速组建海外呼叫中心系统
                                    </p>
                                  </div>
                                </router-link> -->
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="#">行业</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap md">
                      <ul>
                        <li>
                          <a href="#" class="hdng">应用</a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <ul class="list_with_icon">
                                  <li :class="{active: menuActive('/zh-industry-e-commerce')}">
                                    <router-link to="/zh-industry-e-commerce">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/E-commerce.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">跨境电商</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-education')}">
                                    <router-link to="/zh-industry-education">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Education.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text"> 在线教育</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-fintech')}">
                                    <router-link to="/zh-industry-fintech">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Fintech.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">金融科技</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-gaming')}">
                                    <router-link to="/zh-industry-gaming">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Gaming.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">出海游戏</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-logistics')}">
                                    <router-link to="/zh-industry-logistics">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Logistics.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">跨境物流</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-online-social')}">
                                    <router-link
                                      to="/zh-industry-online-social"
                                    >
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Live_Stream.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">在线社交</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-retail')}">
                                    <router-link to="/zh-industry-retail">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/O2O_Solutions.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">实体零售</span>
                                      </div>
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="#">开发者</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap md">
                      <ul>
                        <li>
                          <a href="#" class="hdng">开发者</a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <ul class="list_with_icon">
                                  <li>
                                    <router-link to="/document">
                                      <div class="icon_item">
                                        <span class="text">开发文档</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li>
                                    <router-link to="/zh-service-status">
                                      <div class="icon_item">
                                        <span class="text">服务状态</span>
                                      </div>
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/zh-pricing">价格</router-link>
                    </span>
                  </li>
                  <li>
                    <span class="aWrap">
                      <a href="/news">新闻动态</a>
                    </span>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/zh-company">关于我们</router-link>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="right">
            <router-link to="/zh-login" class="text_btn">登录</router-link>
            <router-link to="/zh-sign-up" class="primary_btn" style="margin-right: 0">注册</router-link>
            <!-- <div class="lang">
              <select
                name="lang"
                id="lang"
                @change="languageChangeFun(language)"
                v-model="language"
              >
                <option value="EN">EN</option>
                <option value="CN" selected>中文</option>
              </select>
            </div> -->
            <button class="toggle-menu"></button>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- ********************* -->
  <!-- Mobile -->
  <!-- ********************* -->
  <header class="mobile">
    <div class="container">
      <div class="header_inner_wrap">
        <div class="inner">
          <router-link to="/" class="logo">
            <img src="../assets/images/logo.svg" alt="NXCLOUD" />
          </router-link>
          <div class="navigation_wrap">
            <div class="inner">
              <nav>
                <ul>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="javascript:;" class="submenu_aWrap">产品中心</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap">
                      <ul>
                        <li>
                          <a href="javascript:;" class="hdng"> 沟通 </a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/zh-product-message">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/message_icon.svg"
                                          alt="chat"
                                        />
                                      </i>
                                      <span class="text">消息</span>
                                    </router-link>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>

                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-message',
                                          query: { id: 'smsOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="smsOtp"
                                          data-url="../zh-product-message"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_01.svg"
                                              class="tm_hdr_icon"
                                            />
                                            国际短信验证码
                                          </h4>
                                          <p>通过短信发送随机数字验证码</p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-message',
                                          query: { id: 'marketingOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="marketingOtp"
                                          data-url="product-message"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_02.svg"
                                              class="tm_hdr_icon"
                                            />
                                            国际营销短信
                                          </h4>
                                          <p>
                                            通过短信进行企业宣传推广
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-message',
                                          query: { id: 'notificationOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="notificationOtp"
                                          data-url="../zh-product-message"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_09.svg"
                                              class="tm_hdr_icon"
                                            />
                                            国际通知短信
                                          </h4>
                                          <p>
                                            向客户发送精准的短信通知
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-message',
                                          query: { id: 'emailOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="emailOtp"
                                          data-url="../zh-product-message"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_05.svg"
                                              class="tm_hdr_icon"
                                            />
                                            邮件验证码
                                          </h4>
                                          <p>通过电子邮件发送验证码消息</p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/zh-product-voice">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/voice_icon.svg"
                                          alt="Voices"
                                        />
                                      </i>
                                      <span class="text">语音</span>
                                    </router-link>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-voice',
                                          query: { id: 'voiceOtp' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="voiceOtp"
                                          data-url="../zh-product-voice"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_06.svg"
                                              class="tm_hdr_icon"
                                            />
                                            语音验证码
                                          </h4>
                                          <p>
                                            通过语音电话播报数字验证码
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-voice',
                                          query: { id: 'voiceNotification' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="voiceNotification"
                                          data-url="../zh-product-voice"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_03.svg"
                                              class="tm_hdr_icon"
                                            />
                                            语音通知
                                          </h4>
                                          <p>
                                            通过语音向用户传递通知信息
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-voice',
                                          query: { id: 'sipTrunk' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="sipTrunk"
                                          data-url="../zh-product-voice"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_07.svg"
                                              class="tm_hdr_icon"
                                            />
                                            SIP 中继
                                          </h4>
                                          <p>
                                            基于会话发起协议的IP电话
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/zh-product-conversation">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/conversation_icon.svg"
                                          alt="Conversation"
                                        />
                                      </i>
                                      <span class="text">社媒对话</span>
                                    </router-link>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul full_width">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-conversation',
                                          query: { id: 'superMessaging' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="superMessaging"
                                          data-url="../zh-product-conversation"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_08.svg"
                                              class="tm_hdr_icon"
                                            />
                                            Super Messaging API
                                          </h4>
                                          <p>
                                            一站式集成全渠道对话API
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <div class="businesses">
                                        <ul>
                                          <li>
                                            <router-link
                                              :to="{
                                                path: '/zh-product-conversation',
                                                query: {
                                                  id: 'whatsappBusiness',
                                                },
                                              }"
                                            >
                                              <div
                                                class="text_wrap tabClickLink"
                                                data-tabclick="whatsappBusiness"
                                                data-url="../zh-product-conversation"
                                              >
                                                <h4>
                                                  <img
                                                    src="../assets/images/whatsapp_clr_icon.svg"
                                                    class="tm_hdr_icon"
                                                    alt="whatsapp"
                                                  />
                                                  WhatsApp Business
                                                </h4>
                                                <p>高效触达全球25亿用户</p>
                                              </div>
                                            </router-link>
                                          </li>
                                          <li>
                                            <router-link
                                              :to="{
                                                path: '/zh-product-conversation',
                                                query: { id: 'viberBusiness' },
                                              }"
                                            >
                                              <div
                                                class="text_wrap tabClickLink"
                                                data-tabclick="viberBusiness"
                                                data-url="../zh-product-conversation"
                                              >
                                                <h4>
                                                  <img
                                                    src="../assets/images/viber_clr_icon.svg"
                                                    class="tm_hdr_icon"
                                                    alt="viber"
                                                  />
                                                  Viber Business
                                                </h4>
                                                <p>提供商业化客户即时通信服务</p>
                                              </div>
                                            </router-link>
                                          </li>
                                          <li>
                                            <router-link
                                              :to="{
                                                path: '/zh-product-conversation',
                                                query: { id: 'zaloBusiness' },
                                              }"
                                            >
                                              <div
                                                class="text_wrap tabClickLink"
                                                data-tabclick="zaloBusiness"
                                                data-url="../zh-product-conversation"
                                              >
                                                <h4>
                                                  <img
                                                    src="../assets/images/zalo_clr_icon.svg"
                                                    class="tm_hdr_icon"
                                                    alt="zalo"
                                                  />
                                                  Zalo Business
                                                </h4>
                                                <p>精准触达超90%越南人口</p>
                                              </div>
                                            </router-link>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="javascript:;" class="hdng"> 应用  </a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/zh-product-fraud-prevention">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/fraud_icon.svg"
                                          alt="fraud"
                                        />
                                      </i>
                                      <span class="text"
                                        >防欺诈策略
                                      </span>
                                    </router-link>
                                    <p class="desc">
                                      防止欺诈活动，确保业务安全
                                    </p>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-fraud-prevention',
                                          query: { id: 'FraudProtector' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="FraudProtector"
                                          data-url="../zh-product-fraud-prevention"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/Fraud_Protector.svg"
                                              class="tm_hdr_icon"
                                            />
                                            防欺诈管理
                                          </h4>
                                          <p>实现防欺诈和风险识别管理</p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-fraud-prevention',
                                          query: { id: 'numberCheck' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="numberCheck"
                                          data-url="../zh-product-fraud-prevention"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_11.svg"
                                              class="tm_hdr_icon"
                                            />
                                            号码检测
                                          </h4>
                                          <p>
                                            批量筛选和检测用户号码
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-fraud-prevention',
                                          query: { id: 'OTA' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="OTA"
                                          data-url="../zh-product-fraud-prevention"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_12.svg"
                                              class="tm_hdr_icon"
                                            />
                                            一键登录（OTA）
                                          </h4>
                                          <p>
                                            一键登录，摆脱繁琐的流程
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link to="/zh-product-global-number">
                                      <i class="icon">
                                        <img
                                          src="../assets/images/hash_icon.svg"
                                          alt="global"
                                        />
                                      </i>
                                      <span class="text"
                                        >全球号码</span
                                      >
                                    </router-link>
                                    <p class="desc">
                                      在任何地方呼叫和接听各国电话
                                    </p>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-global-number',
                                          query: { id: 'privacyNumber' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="privacyNumber"
                                          data-url="../zh-product-global-number"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_10.svg"
                                              class="tm_hdr_icon"
                                            />
                                            隐私号码
                                          </h4>
                                          <p>
                                            保护用户隐私不透露真实号码<br/>
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-global-number',
                                          query: { id: 'globalNumber' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="globalNumber"
                                          data-url="../zh-product-global-number"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_13.svg"
                                              class="tm_hdr_icon"
                                            />
                                            SMS A2P, P2A
                                          </h4>
                                          <p>
                                            通过本地号码发送上下行短信
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  class="icon_wrap nested_dropdown_trigger_wrap"
                                >
                                  <div class="left">
                                    <router-link
                                      to="/zh-product-customer-engagement"
                                    >
                                      <i class="icon">
                                        <img
                                          src="../assets/images/support_icon.svg"
                                          alt="customer_engaging"
                                        />
                                      </i>
                                      <span class="text"
                                        >客户互动</span
                                      >
                                    </router-link>
                                    <p class="desc">
                                      全渠道客户互动，提升营销转化
                                    </p>
                                  </div>
                                  <div class="right">
                                    <button
                                      class="nested_dropdown_trigger"
                                    ></button>
                                  </div>
                                </div>
                                <div class="nested_dropdown">
                                  <ul class="nested_dropdown_ul">
                                    <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-customer-engagement',
                                          query: { id: 'NXLink' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="NXLink"
                                          data-url="../zh-product-customer-engagement"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_14.svg"
                                              class="tm_hdr_icon"
                                            />
                                            NXLink
                                          </h4>
                                          <p>
                                            - 提供高效的海外数字营销服务<br/>- 快速搭建海外智能呼叫中心
                                          </p>
                                        </div>
                                      </router-link>
                                    </li>
                                    <!-- <li>
                                      <router-link
                                        :to="{
                                          path: '/zh-product-customer-engagement',
                                          query: { id: 'AICC' },
                                        }"
                                      >
                                        <div
                                          class="text_wrap tabClickLink"
                                          data-tabclick="AICC"
                                          data-url="../zh-product-customer-engagement"
                                        >
                                          <h4>
                                            <img
                                              src="../assets/images/hdr_icon_15.svg"
                                              class="tm_hdr_icon"
                                            />
                                            AICC
                                          </h4>
                                          <p>
                                            一站式SaaS通讯平台，快速组建海外呼叫中心系统
                                          </p>
                                        </div>
                                      </router-link>
                                    </li> -->
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="javascript:;" class="submenu_aWrap">行业</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap md">
                      <ul>
                        <li>
                          <a href="javascript:;" class="hdng">应用</a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <ul class="list_with_icon">
                                  <li :class="{active: menuActive('/zh-industry-e-commerce')}">
                                    <router-link to="/zh-industry-e-commerce">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/E-commerce.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">跨境电商</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-education')}">
                                    <router-link to="/zh-industry-education">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Education.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text"> 在线教育</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-fintech')}">
                                    <router-link to="/zh-industry-fintech">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Fintech.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">金融科技</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-gaming')}">
                                    <router-link to="/zh-industry-gaming">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Gaming.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">出海游戏</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-logistics')}">
                                    <router-link to="/zh-industry-logistics">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Logistics.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">跨境物流</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-online-social')}">
                                    <router-link to="/zh-industry-online-social">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/Live_Stream.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">在线社交</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li :class="{active: menuActive('/zh-industry-retail')}">
                                    <router-link to="/zh-industry-retail">
                                      <div class="icon_item">
                                        <i class="icon">
                                          <img
                                            src="../assets/images/O2O_Solutions.svg"
                                            alt="icon"
                                          />
                                        </i>
                                        <span class="text">实体零售</span>
                                      </div>
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="has_submenu">
                    <span class="aWrap">
                      <a href="javascript:;" class="submenu_aWrap">开发者</a>
                      <button class="submenu_arrow"></button>
                    </span>
                    <div class="submenu megamenu_wrap md">
                      <ul>
                        <li>
                          <a href="javascript:;" class="hdng">开发者</a>
                          <div class="content">
                            <div class="c_row">
                              <div class="col">
                                <ul class="list_with_icon">
                                  <li>
                                    <router-link to="/document">
                                      <div class="icon_item">
                                        <span class="text">开发文档</span>
                                      </div>
                                    </router-link>
                                  </li>
                                  <li>
                                    <router-link to="/zh-service-status">
                                      <div class="icon_item">
                                        <span class="text">服务状态</span>
                                      </div>
                                    </router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/zh-pricing">价格</router-link>
                    </span>
                  </li>
                  <li>
                    <span class="aWrap">
                      <router-link to="/zh-company">关于我们</router-link>
                    </span>
                  </li>
                </ul>
              </nav>
              <div class="header_btns_mobile">
                <router-link to="/zh-sign-up" class="primary_btn"
                  >Get Started</router-link
                >
                <router-link to="/zh-login" class="text_btn">Login</router-link>
              </div>
            </div>
          </div>
          <div class="right">
            <!-- <div class="lang">
              <select
                name="lang"
                id="lang"
                @change="languageChangeFun(language)"
                v-model="language"
              >
                <option value="EN">EN</option>
                <option value="CN" selected>中文</option>
              </select>
            </div> -->
            <button class="toggle-menu"></button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderCh",
  data() {
    return {
      language: "CN",
      headerToggle: false,
      innerheight: ''
    };
  },
  props: {
    languageChangeFun: Function,
  },
  watch: {
    // language(val, prev) {
    //   console.log(prev);
    //   sessionStorage.setItem("language", val);
    // },
  },
  methods: {
    // Function to update component data with session data
    updateDataFromSession() {
      // Get session data
      const language = sessionStorage.getItem("language");
      console.log("language " + language);
      // Parse session data if available
      if (language) {
        console.log("session" + language);
        this.language = language;
        this.languageChangeFun(language);
      }
    },
    menuActive(path) {
      return this.$route.fullPath === path;
    },
  },
  created() {
    // Call the updateDataFromSession method when the component is created
    // this.updateDataFromSession();
    this.innerheight = window.innerHeight
  },
};
</script>
<style>
.submenuScroll{
  height: 350px;
  overflow-y: scroll;
}
</style>
