import { createWebHistory, createRouter } from "vue-router";

// English pages
// import Home from "./components/pages/HomePage.vue";
// import ProductMessage from "./components/pages/ProductMessage.vue";
// import ProductVoice from "./components/pages/ProductVoice.vue";
// import ProductConversation from "./components/pages/ProductConversation.vue";
// import ProductFraudPrevention from "./components/pages/ProductFraudPrevention.vue";
// import ProductGlobalNumber from "./components/pages/ProductGlobalNumber.vue";
// import ProductCustomerEngagement from "./components/pages/ProductCustomerEngagement.vue";
// import IndustryLogistics from "./components/pages/IndustryLogistics.vue";
// import IndustryEcommerce from "./components/pages/IndustryEcommerce.vue";
// import IndustryRetail from "./components/pages/IndustryRetail.vue";
// import IndustryEducation from "./components/pages/IndustryEducation.vue";
// import IndustryGaming from "./components/pages/IndustryGaming.vue";
// import IndustryOnlineSocial from "./components/pages/IndustryOnlineSocial.vue";
// import IndustryFintech from "./components/pages/IndustryFintech.vue";
// import CompanyPage from "./components/pages/CompanyPage.vue";
// import DeveloperPage from "./components/pages/DeveloperPage.vue";
// import PricingPage from "./components/pages/PricingPage.vue";
// import LoginPage from "./components/pages/LoginPage.vue";
// import ForgetPassword from "./components/pages/ForgetPassword.vue";
// import PrivacyPolicy from "./components/pages/PrivacyPolicy.vue";
// import CookiePolicy from "./components/pages/CookiePolicy.vue";
// import TermsAndServices from "./components/pages/TermsAndServices.vue";
// import SignupPage from "./components/pages/SignupPage.vue";
// 404 page not found
// import pageNotFound from "./components/pages/pageNotFound.vue";

// Chinese pages
import ZhHomePage from "./components/pages/ZhHomePage.vue";
import ZhProductMessage from "./components/pages/ZhProductMessage.vue";
import ZhProductVoice from "./components/pages/ZhProductVoice.vue";
import ZhProductConversation from "./components/pages/ZhProductConversation.vue";
import ZhProductFraudPrevention from "./components/pages/ZhProductFraudPrevention.vue";
import ZhProductGlobalNumber from "./components/pages/ZhProductGlobalNumber.vue";
import ZhProductCustomerEngagement from "./components/pages/ZhProductCustomerEngagement.vue";
import ZhIndustryLogistics from "./components/pages/ZhIndustryLogistics.vue";
import ZhIndustryEcommerce from "./components/pages/ZhIndustryEcommerce.vue";
import ZhIndustryRetail from "./components/pages/ZhIndustryRetail.vue";
import ZhIndustryEducation from "./components/pages/ZhIndustryEducation.vue";
import ZhIndustryGaming from "./components/pages/ZhIndustryGaming.vue";
import ZhIndustryOnlineSocial from "./components/pages/ZhIndustryOnlineSocial.vue";
import ZhIndustryFintech from "./components/pages/ZhIndustryFintech.vue";
import ZhCompanyPage from "./components/pages/ZhCompanyPage.vue";
// import ZhDeveloperPage from "./components/pages/ZhDeveloperPage.vue";
import ZhPricingPage from "./components/pages/ZhPricingPage.vue";
import ZhLoginPage from "./components/pages/ZhLoginPage.vue";
import ZhForgetPassword from "./components/pages/ZhForgetPassword.vue";
import ZhPrivacyPolicy from "./components/pages/ZhPrivacyPolicy.vue";
import ZhCookiePolicy from "./components/pages/ZhCookiePolicy.vue";
import ZhTermsAndServices from "./components/pages/ZhTermsAndServices.vue";
import ZhSignUp from "./components/pages/ZhSignUp.vue";
// 404 page not found
import zhPageNotFound from "./components/pages/zhPageNotFound.vue";

import ZhDeveloperPageNew from "./components/pages/ZhDeveloperPageNew.vue";

import ServiceStatus from "./components/pages/serviceStatus.vue";
import ServiceStatusRecord from "./components/pages/serviceStatusRecord.vue";

const routes = [
    // {
    //     name: 'Home',
    //     path: '/',
    //     component: Home,
    //     meta: {
    //         title: 'SMS Messaging, Voice, WhatsApp Business, Number | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD helps businesses communicate with your customers via SMS, Voice, WhatsApp Business, Global Number, Viber Business, Zalo Business, Email, and build customers solutions.'
    //     }
    // },
    // {
    //     name: 'ProductMessage',
    //     path: '/product-message',
    //     component: ProductMessage,
    //     meta: {
    //         title: 'SMS service and SMS API for business | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD helps businesses communicate with your customers via SMS.Use SMS OTP to secure your data, accounts, and user transactions.Use SMS marketing to reach customers instantly.'
    //     }
    // },
    // {
    //     name: 'ProductVoice',
    //     path: '/product-voice',
    //     component: ProductVoice,
    //     meta: {
    //         title: 'Voice API: Connect with Customers Worldwide | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD helps businesses Connect with customers around the world using an industry-leading voice call and messaging service.'
    //     }
    // },
    // {
    //     name: 'ProductConversation',
    //     path: '/product-conversation',
    //     component: ProductConversation,
    //     meta: {
    //         title: 'WhatsApp Business API & Viber Business API and Zalo Business API | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD helps businesses communicate with your customers via WhatsApp Business, Viber Business and Zalo Business.TO enables businesses to automate communications with automated replies, WhatsApp chatbots & interactive messages.'
    //     }
    // },
    // {
    //     name: 'ProductFraudPrevention',
    //     path: '/product-fraud-prevention',
    //     component: ProductFraudPrevention,
    //     meta: {
    //         title: 'Phone Number Lookup and one-tap authentication | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides phone number lookup globally for business to improve business efficiency.'
    //     }
    // },
    // {
    //     name: 'ProductGlobalNumber',
    //     path: '/product-global-number',
    //     component: ProductGlobalNumber,
    //     meta: {
    //         title: 'Virtual Phone Numbers for Business | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides Virtual Phone Numbers for Business to connect individuals and businesses locally and globally.'
    //     }
    // },
    // {
    //     name: 'ProductCustomerEngagement',
    //     path: '/product-customer-engagement',
    //     component: ProductCustomerEngagement,
    //     meta: {
    //         title: 'Combine SMS, Voice, WhatsApp，Viber and Call center system for marketing | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD helps businesses with marketing via bulking WhatsApp, Viber, Zalo, SMS message and communicate with customers by Call center system.'
    //     }
    // },
    // {
    //     name: 'IndustryLogistics',
    //     path: '/industry-logistics',
    //     component: IndustryLogistics,
    //     meta: {
    //         title: 'Solutions for Cross-border Logistics in Communication Industry | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides Solutions for Logistics in the communication industry to reduce COD refusal rate.'
    //     }
    // },
    // {
    //     name: 'IndustryEcommerce',
    //     path: '/industry-e-commerce',
    //     component: IndustryEcommerce,
    //     meta: {
    //         title: 'Solutions for E-commerce in Communication Industry | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides omni-channel customer service to assist businesses in achieving elevated customer conversion rate at lower marketing cost.'
    //     }
    // },
    // {
    //     name: 'IndustryRetail',
    //     path: '/industry-retail',
    //     component: IndustryRetail,
    //     meta: {
    //         title: 'Solutions for Local Online-to-Offline in Communication Industry | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides Solutions for Retail in the communication industry to achieve customer acquisition.'
    //     }
    // },
    // {
    //     name: 'IndustryEducation',
    //     path: '/industry-education',
    //     component: IndustryEducation,
    //     meta: {
    //         title: 'Solutions for Online Education in Communication Industry | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides Solutions for Online Education in the communication industry to reduce operational cost and elevated customers acquisition and retention rate.'
    //     }
    // },
    // {
    //     name: 'IndustryGaming',
    //     path: '/industry-gaming',
    //     component: IndustryGaming,
    //     meta: {
    //         title: 'Solutions for Overseas Game in Communication Industry | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides Solutions for global Game in the communication industry to boost customer retention rate and augment customer loyalty.'
    //     }
    // },
    // {
    //     name: 'IndustryOnlineSocial',
    //     path: '/industry-online-social',
    //     component: IndustryOnlineSocial,
    //     meta: {
    //         title: 'Solutions for Online Social in Communication Industry | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides solutions for Online Social in the communication industry to engage users and augment user loyalty through interactive efforts.'
    //     }
    // },
    // {
    //     name: 'IndustryFintech',
    //     path: '/industry-fintech',
    //     component: IndustryFintech,
    //     meta: {
    //         title: 'Solutions for Fintech in Communication Industry | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD provides Solutions for Fintech in the communication industry to boost sales conversion and debt payment rate.'
    //     }
    // },
    // {
    //     name: 'CompanyPage',
    //     path: '/company',
    //     component: CompanyPage,
    //     meta: {
    //         title: 'NXCLOUD - About the Cloud Communications Company',
    //         key: '',
    //         description: 'NXCLOUD helps businesses communicate with your customers via SMS, Voice, WhatsApp Business, Global Number, Viber Business, Zalo Business, Email, and build customers solutions.'
    //     }
    // },
    // {
    //     name: 'DeveloperPage',
    //     path: '/document-en/:catogory?/:doc?',
    //     component: DeveloperPage,
    //     meta: {
    //         title: 'Developer',
    //     }
    // },
    // {
    //     name: 'PricingPage',
    //     path: '/pricing',
    //     component: PricingPage,
    //     meta: {
    //         title: 'SMS Pricing Voice API Pricing for per Country | NXCLOUD',
    //         key: '',
    //         description: 'NXCLOUD helps businesses communicate with your customers via SMS, Voice, WhatsApp Business, Global Number, Viber Business, Zalo Business, Email, and build customers solutions.'
    //     }
    // },
    // {
    //     name: 'LoginPage',
    //     path: '/login',
    //     component: LoginPage,
    //     meta: {
    //         title: 'Login' // Set the title for this route
    //     }
    // },
    // {
    //     name: 'ForgetPassword',
    //     path: '/forget-password',
    //     component: ForgetPassword,
    //     meta: {
    //         title: 'Forget Password' // Set the title for this route
    //     }
    // },
    // {
    //     name: 'PrivacyPolicy',
    //     path: '/privacy-policy',
    //     component: PrivacyPolicy,
    //     meta: {
    //         title: 'Privacy Policy' // Set the title for this route
    //     }
    // },
    // {
    //     name: 'CookiePolicy',
    //     path: '/cookie-policy',
    //     component: CookiePolicy,
    //     meta: {
    //         title: 'cookie Policy' // Set the title for this route
    //     }
    // },
    // {
    //     name: 'TermsAndServices',
    //     path: '/terms-services',
    //     component: TermsAndServices,
    //     meta: {
    //         title: 'Service Agreement' // Set the title for this route
    //     }
    // },
    // {
    //     name: 'SignupPage',
    //     path: '/sign-up',
    //     component: SignupPage,
    //     meta: {
    //         title: 'Sign Up' // Set the title for this route
    //     }
    // },
    // {
    //     name: 'pageNotFound',
    //     path: '/page-not-found',
    //     component: pageNotFound,
    //     meta: {
    //         title: 'Page not found' // Set the title for this route
    //     }
    // },
    {
        name: 'Home',
        path: '/',
        component: ZhHomePage,
        meta: {
            title: '国际短信_WhatsApp Business_海外呼叫中心_全球语音_全球虚拟号码-NXCLOUD牛信云通讯-宁波昶联', // Set the title for this route
            key: '国际短信|WhatsApp Business|海外呼叫中心|全球语音|全球虚拟号码|智能外呼机器人',
            description: '牛信云为出海企业提供各种云通信服务，包括国际短信、WhatsApp Businesss、海外呼叫中心（AICC)、全球语音、全球虚拟号码（DID）、智能外呼机器人（NXCallbot）等服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhHomePage',
        path: '/zh',
        component: ZhHomePage,
        meta: {
            title: '国际短信_WhatsApp Business_海外呼叫中心_全球语音_全球虚拟号码-NXCLOUD牛信云通讯-宁波昶联', // Set the title for this route
            key: '国际短信|WhatsApp Business|海外呼叫中心|全球语音|全球虚拟号码|智能外呼机器人',
            description: '牛信云为出海企业提供各种云通信服务，包括国际短信、WhatsApp Businesss、海外呼叫中心（AICC)、全球语音、全球虚拟号码（DID）、智能外呼机器人（NXCallbot）等服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhProductMessage',
        path: '/zh-product-message',
        component: ZhProductMessage,
        meta: {
            title: '国际短信平台_国际营销短信_国际通知短信_国际短信验证码_国际短信群发-NXCLOUD牛信云通讯-宁波昶联', // Set the title for this route
            key: '国际短信平台|国际营销短信|国际通知短信|国际短信验证码|国际短信群发',
            description: '牛信云通信平台（nxcloud）为出海企业提供国际短信开发API接口，业务涵盖：国际短信验证码、国际营销短信、国际通知短信、国际短信群发等短信云服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhProductVoice',
        path: '/zh-product-voice',
        component: ZhProductVoice,
        meta: {
            title: 'SIP语音中继_语音群呼国际线路_语音通知系统_全球语音验证码平台-NXCLOUD牛信云通讯-宁波昶联',
            key: 'SIP语音中继|语音群呼国际线路|语音通知系统|全球语音验证码平台',
            description: '牛信云通信平台（nxcloud）为跨境电商、独立站等出海企业提供语音群呼国际线路、SIP语音中继、国际语音通知、全球语音验证码平台等服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhProductConversation',
        path: '/zh-product-conversation',
        component: ZhProductConversation,
        meta: {
            title: 'WhatsApp Business_Viber Business_Zalo Business-NXCLOUD牛信云通讯-宁波昶联',
            key: 'WhatsApp Business|Viber Business|Zalo Business',
            description: '牛信云为出海企业提供全球社媒服务，包括WhatsApp Business、Viber Businesss、Zalo Businesss等服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhProductFraudPrevention',
        path: '/zh-product-fraud-prevention',
        component: ZhProductFraudPrevention,
        meta: {
            title: '全球号码检测_国际号码检测空号_海外号码一键登录-NXCLOUD牛信云通讯-宁波昶联',
            key: '全球号码检测|国际号码检测空号|海外号码一键登录',
            description: '牛信云通信平台（nxcloud）为跨境电商、独立站等出海企业提供全球号码检测、国际号码检测空号、海外号码一键登录等服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhProductGlobalNumber',
        path: '/zh-product-global-number',
        component: ZhProductGlobalNumber,
        meta: {
            title: 'DID号码_全球虚拟号码_国际号码_全球隐私号码_国际中间号码-NXCLOUD牛信云通讯-宁波昶联',
            key: 'DID号码|全球虚拟号码|国际号码|全球隐私号码|国际中间号码',
            description: '牛信云通信平台（nxcloud）为出海企业提供全球DID号码，包括国外手机号码，海外固定电话、国际号码、隐私号和中间号服务，为跨境电商、在线社交、本地生活、出海游戏、视频直播、实体行业、在线会议、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhProductCustomerEngagement',
        path: '/zh-product-customer-engagement',
        component: ZhProductCustomerEngagement,
        meta: {
            title: 'NXLINK_WhatsApp群发_WhatsApp营销_Viber群发_Viber营销--NXCLOUD牛信云通讯-宁波昶联',
            key: 'NXLink|WhatsApp群发|WhatsApp营销|Viber群发|Viber营销',
            description: '牛信云是Meta官方授权的WhatsApp Businesss商业解决方案提供商，也是Viber官方的商务合作伙伴。NXLink是牛信云旗下的智能营销系统。它一站式集成WhatsApp群发、国际短信群发、国际语音群发、Viber群发等营销渠道。可以提供WhatsApp营销、Viber群发、WhatsApp群发、Viber营销,是企业营销、获客、引流、私域、推广、售后服务支持的重要工具。'
        }
    },
    {
        name: 'ZhIndustryLogistics',
        path: '/zh-industry-logistics',
        component: ZhIndustryLogistics,
        meta: {
            title: '跨境物流通信服务一站式解决方案-NXCLOUD牛信云通讯-宁波昶联',
            key: '跨境物流通信服务一站式解决方案',
            description: '牛信云通信平台（nxcloud）为跨境物流行业的出海企业提供国际线路的短信、语音、邮件、智能外呼、呼叫中心系统、跨境营销工具等国际云通信服务服务，为跨境物流行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhIndustryEcommerce',
        path: '/zh-industry-e-commerce',
        component: ZhIndustryEcommerce,
        meta: {
            title: '跨境电商出海通信服务一站式解决方案-NXCLOUD牛信云通讯-宁波昶联',
            key: '跨境电商出海通信服务一站式解决方案',
            description: '牛信云通信平台（nxcloud）为跨境电商、独立站、亚马逊等平台的出海企业提供国际线路的短信、语音、邮件、智能外呼、呼叫中心系统、跨境营销工具等国际云通信服务服务，为外贸行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhIndustryRetail',
        path: '/zh-industry-retail',
        component: ZhIndustryRetail,
        meta: {
            title: '零售行业出海通信服务一站式解决方案-NXCLOUD牛信云通讯-宁波昶联',
            key: '零售行业出海通信服务一站式解决方案',
            description: '牛信云通信平台（nxcloud）为零售行业出海企业提供国际线路的短信、语音、邮件、智能外呼、呼叫中心系统、跨境营销工具等国际云通信服务服务，为零售行业行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhIndustryEducation',
        path: '/zh-industry-education',
        component: ZhIndustryEducation,
        meta: {
            title: '在线教育出海通信服务一站式解决方案-NXCLOUD牛信云通讯-宁波昶联',
            key: '在线教育出海通信服务一站式解决方案',
            description: '牛信云通信平台（nxcloud）为在线教育的出海企业提供国际线路的短信、语音、邮件、智能外呼、呼叫中心系统、跨境营销工具等国际云通信服务服务，为在线教育行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhIndustryGaming',
        path: '/zh-industry-gaming',
        component: ZhIndustryGaming,
        meta: {
            title: '游戏出海通信服务一站式解决方案-NXCLOUD牛信云通讯-宁波昶联',
            key: '游戏出海通信服务一站式解决方案',
            description: '牛信云通信平台（nxcloud）为游戏行业的出海企业提供国际线路的短信、语音、邮件、智能外呼、呼叫中心系统、跨境营销工具等国际云通信服务服务，为出海游戏行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhIndustryOnlineSocial',
        path: '/zh-industry-online-social',
        component: ZhIndustryOnlineSocial,
        meta: {
            title: '在线社交出海通信服务一站式解决方案-NXCLOUD牛信云通讯-宁波昶联',
            key: '在线社交出海通信服务一站式解决方案',
            description: '牛信云通信平台（nxcloud）为在线社交出海企业提供国际线路的短信、语音、邮件、智能外呼、呼叫中心系统、跨境营销工具等国际云通信服务服务，为在线社交行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhIndustryFintech',
        path: '/zh-industry-fintech',
        component: ZhIndustryFintech,
        meta: {
            title: '金融行业出海通信服务一站式解决方案-NXCLOUD牛信云通讯-宁波昶联',
            key: '金融行业出海通信服务一站式解决方案',
            description: '牛信云通信平台（nxcloud）为金融行业的出海企业提供国际线路的短信、语音、邮件、智能外呼、呼叫中心系统、跨境营销工具等国际云通信服务服务，为金融行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhCompanyPage',
        path: '/zh-company',
        component: ZhCompanyPage,
        meta: {
            title: '国际短信平台_智能呼叫中心_语音验证码_虚拟号码-NXCLOUD牛信云通讯-宁波昶联',
            key: '牛信云公司简介',
            description: '牛信云为出海企业提供各种云通信服务，包括国际短信、WhatsApp Businesss、海外呼叫中心（AICC)、全球语音、全球虚拟号码（DID）、智能外呼机器人（NXCallbot）等服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    // {
    //     name: 'ZhDeveloperPage',
    //     path: '/zh-developer-page',
    //     component: ZhDeveloperPage,
    //     meta: {
    //         title: 'API开发文档-NXCLOUD牛信云通讯',
    //         key: 'API开发文档',
    //         description: '牛信云为出海企业提供各种云通信服务，提供国际短信价格、WhatsApp Business API、海外呼叫中心API、全球语音API、全球虚拟号码API、智能外呼机器人API等服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
    //     }
    // },
    { 
        name: 'ZhDeveloperPage',
        path: '/document/:catogory/:doc?', 
        component: ZhDeveloperPageNew,
        meta: {
          title: 'API开发文档-NXCLOUD牛信云通讯-宁波昶联',
          key: 'API开发文档',
          description: '牛信云为出海企业提供各种云通信服务，提供国际短信价格、WhatsApp Business API、海外呼叫中心API、全球语音API、全球虚拟号码API、智能外呼机器人API等服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhPricingPage',
        path: '/zh-pricing',
        component: ZhPricingPage,
        meta: {
            title: '国际短信收费标准_全球语音价格-NXCLOUD牛信云通讯-宁波昶联',
            key: '国际短信多少钱一条|国际短信收费标准|国际短信资费|国际语音价格',
            description: '牛信云为出海企业提供各种云通信服务，提供国际短信收费标准、全球语音收费等报价服务，为外贸、跨境电商、在线社交、出海游戏、金融支付等行业提供一站式出海通信解决方案。'
        }
    },
    {
        name: 'ZhLoginPage',
        path: '/zh-login',
        component: ZhLoginPage,
        meta: {
            title: '登录' // Set the title for this route
        }
    },
    {
        name: 'ZhForgetPassword',
        path: '/zh-forget-password',
        component: ZhForgetPassword,
        meta: {
            title: '忘记密码' // Set the title for this route
        }
    },
    {
        name: 'ZhPrivacyPolicy',
        path: '/zh-privacy-policy',
        component: ZhPrivacyPolicy,
        meta: {
            title: '隐私政策' // Set the title for this route
        }
    },
    {
        name: 'ZhCookiePolicy',
        path: '/zh-cookie-policy',
        component: ZhCookiePolicy,
        meta: {
            title: '隐私政策' // Set the title for this route
        }
    },
    {
        name: 'ZhTermsAndServices',
        path: '/zh-terms-services',
        component: ZhTermsAndServices,
        meta: {
            title: '服务协议' // Set the title for this route
        }
    },
    {
        name: 'ZhSignUp',
        path: '/zh-sign-up',
        component: ZhSignUp,
        meta: {
            title: '登录' // Set the title for this route
        }
    },
    {
      name: 'ZhServiceStatus',
      path: '/zh-service-status',
      component: ServiceStatus,
      meta: {
          title: '服务状态' // Set the title for this route
      }
    },
    {
      name: 'ZhServiceStatusRecord',
      path: '/zh-service-status-record',
      component: ServiceStatusRecord,
      meta: {
          title: '历史记录' // Set the title for this route
      }
    },
    {
        name: 'zhPageNotFound',
        path: '/zh-page-not-found',
        component: zhPageNotFound,
        meta: {
            title: '找不到网页' // Set the title for this route
        }
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes
});


export default router;