<template>
  <main>
    <section class="terms">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="terms-hdng center-align">昶联平台服务协议</h1>
            <p class="terms-desc term-days right-align">
              生效日期：2024年8月1日
            </p>
            <p class="terms-desc">
              欢迎您使用宁波昶联软件有限公司提供的平台服务（以下简称“昶联”），请仔细阅读并遵守《昶联平台服务协议》（以下简称“本协议”）。在接受本协议之前，请您务必仔细阅读本协议的全部内容，特别是免除或者限制责任的条款以及管辖法院的选择条款等，
              限制、免责条款可能以加粗形式提示您注意。无论是否实际阅读本协议，您通过网络页面点击确认本协议或实际使用平台服务，均表示您与昶联方已就本协议达成一致并同意接受本协议的全部约定内容。如果您不同意本协议的任意内容，或者无法准确理解昶联方对条款的解释，请不要同意本协议或使用本协议项下的服务。否则，表示您已接受了以下所述的条款和条件，同意受本协议约束。本协议由昶联与您签署。
            </p>

            <p class="terms-desc">
              除本协议外，昶联还将发布其他规则和政策，如 《昶联隐私政策》 。
              对于昶联发布的其他规则和政策，以相应的规则和政策为准，相应的规则和政策未提及的事项，以本协议为准。
            </p>

            <p class="terms-desc">
              我们的网站面向主体及服务对象仅限于依住所地法律合法登记并有效存续且有能力履行本协议项下义务的企业法人或其他组织。
              如您不符合资格，请勿继续使用我们提供的服务，并且，我们有权随时终止向您提供服务。
            </p>

            <p class="terms-desc"><strong>第一条 定义</strong></p>
            <p class="terms-desc">
              1.1 昶联：指由我们所拥有、控制、运营的昶联服务平台（<a href="javascript:;">www.solutioncl.com</a>)。
            </p>
            <p class="terms-desc">
              1.2
              您或用户：指依住所地法律合法登记并有效存续且有能力履行本协议项下义务的法人或其他组织。
            </p>
            <p class="terms-desc">
              1.3 您的终端用户：指使用您的产品和/或服务的终端用户。
            </p>
            <p class="terms-desc">
              1.4
              昶联服务：由昶联开发并运营的国际短信、国际语言、社媒对话等服务的总称。
            </p>
            <p class="terms-desc">
              1.5
              账户：指在通过以您自身名义申请注册的昶联账户进行登录后，用以接收昶联服务的昶联账户。
            </p>
            <strong>
              <p class="terms-desc">第二条 服务内容</p>
              <p class="terms-desc">
                2.1
                昶联的服务内容由宁波昶联软件有限公司根据实际情况提供给您，我们保留随时修改本条款的权利，并会在修改后于本页面公布修改后的文本，因此，请经常查看本页。如果您继续使用我们的服务，就视同接受我们对本条款的修改。
              </p>

              <p class="terms-desc">第三条 您的权利及义务</p>
              <p class="terms-desc">3.1 注册与使用</p>
              <p class="terms-desc">
                3.1.1
                您确认，在您完成注册程序或以其他平台允许的方式实际使用服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且昶联有权注销（永久冻结）您的账户，并向您及您的监护人索偿。如您代表一家公司或其他法律主体进行注册或以其他平台允许的方式实际使用本服务，则您声明和保证，您有权使该公司或该法律主体受本协议“条款“的约束。
              </p>
              <p class="terms-desc">
                3.1.2
                您应按照平台要求提交注册信息，并保证其提交注册信息真实、有效。您的相关注册信息发生变化时，应当在变更后1个工作日内修改相关注册信息。由于您提交虚假注册信息、提交信息不准确或未及时更新，昶联有权停止您服务，导致的任何损失或责任由您自行承担。
              </p>
              <p class="terms-desc">
                3.1.3
                您承诺注册的账号名称、标识和简介等信息中不得出现违法和不良信息，不得冒充他人，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册账号，不得使用可能侵犯他人权益的用户名（包括但不限于涉嫌商标权、名誉权侵权等），否则昶联有权不予注册或停止服务并收回账号，因此产生的损失由您自行承担。
              </p>
            </strong>
            <p class="terms-desc">
              3.1.4
              您了解并同意，注册账号所有权归属于昶联，注册完成后，您仅获得账号使用权。账号使用权仅归属于初始申请注册人，不得以任何方式转让或被提供予他人使用，否则，昶联有权立即不经通知收回该账号，由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。
            </p>
            <p class="terms-desc">
              3.1.5在您成功注册后，昶联将根据账号和密码确认您的身份。您应妥善保管您的终端及账户和密码，并对利用该账户和密码所进行的一切活动负全部责任。您承诺，在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知平台，且您同意并确认，昶联不对上述情形产生的任何直接或间接的遗失或损害承担责任。除非有法律规定或司法裁定，且征得昶联的同意，否则，您的账户、密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。
            </p>
            <p class="terms-desc">
              3.1.6
              您了解并同意，如您注册账号后连续超过6个月未登录，昶联为网站优化管理之目的有权回收该账号，相关问题及责任均由您自行承担。
            </p>
            <p class="terms-desc">
              3.1.7
              昶联根据本协议收回或取消账号后，有权自行对账号相关内容及信息以包括但不限于删除等方式进行处理，且无需就此向用户承担任何责任。
            </p>
            <p class="terms-desc">
              3.1.8
              在协议解除归责于昶联的情况下，昶联将账户的余额退还甲方，在其他情况下解除的，昶联不予退还账户余额。
            </p>

            <p class="terms-desc">3.2账户安全</p>
            <p class="terms-desc">
              3.2.1
              您须自行负责对您的昶联账户和密码保密，且须对您在该登录名和密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议或发布消息等）承担责任。
              您需确保您在每个上网时段结束时，以正确步骤离开网站。昶联不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。
            </p>
            <p class="terms-desc">
              3.2.2
              若您发现有他人盗用您的账号及密码，或任何其他未经您合法授权的情形时，您应立即以有效方式通知平台并提供必要资料（如客户资料、情况说明、证明材料及诉求等，以便平台核实身份及事件）
              。平台收到您的有效通知并核实身份后，会依据法律法规及服务规则进行处理。平台依据本条进行处理产生的相关责任和后果由您承担。
            </p>
            <p class="terms-desc">
              若您提供的资料存在瑕疵，导致昶联无法核实您的身份或无法判断您的需求等，而导致平台未能及时处理，给您带来的损失，应由您自行承担。同时，您理解，平台对您的请求进行处理需要合理期限，对于平台采取措施前您已经产生的损失以及采取措施后因不可归责于平台的原因导致的损失，昶联不承担任何责任。
            </p>
            <p class="terms-desc">
              3.2.3
              您理解并同意，昶联有权了解您使用本网站产品及服务的真实背景及目的，有权要求您如实提供真实、全面、准确的信息；如果平台有合理理由怀疑您提供的信息不真实、您进行虚假交易，或您的行为违反平台的网站规则的，平台有权暂时或永久限制您账户下所使用的所有产品及/或服务的部分或全部功能。
            </p>
            <p class="terms-desc">
              3.2.4
              您理解并同意，基于运行和交易安全的需要，昶联有权暂时停止或者限制您账号下部分或全部的资金支付功能
              ，平台将通过邮件、站内信、短信或电话等方式通知您，您应及时予以关注并按照程序进行申诉等后续操作。
            </p>
            <strong>
              <p class="terms-desc">3.3 使用规范</p>
              <p class="terms-desc">
                3.3.1您应对自己在使用昶联服务过程中的行为及与其客户之间的权利义务承担法律责任，您应依法进行应用的运营活动并独立承担相应的法律责任。您违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，您同意赔偿昶联及其关联公司和合作公司，并使之免受损害。
              </p>
              <p class="terms-desc">
                3.3.2昶联可根据其自身运营安排或法律法规要求等随时单方调整、修订平台规则。
              </p>
              <p class="terms-desc">
                （1）昶联有权自行或通过电话、短信、电子邮件、自助系统、站内消息、网站公告等方式中的一种或多种，向您通知平台规则，您应当及时查看相关内容。
              </p>
              <p class="terms-desc">
                （2）经调整、修订的昶联规则自通知规定的生效日起生效，您应当遵守。昶联规则调整后，可能引起准入标准和推广标准等的变化，您不得以旧的标准为理由要求平台为您提供服务。
              </p>
              <p class="terms-desc">
                （3）如您不同意上述调整、修订的，您应立即停止使用昶联提供的服务并书面通知昶联终止本协议。您以任何方式继续使用昶联服务的，即视为您认可并接受相关昶联规则。
              </p>
              <p class="terms-desc">
                3.3.3您理解并同意，昶联一直致力于为用户提供文明健康、规范有序的网络环境，您不得利用本平台及服务制作、复制、发布、传播如下干扰网络正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：
              </p>
              <p class="terms-desc">
                （1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽或含有任何性或性暗示的、暴力的内容；
              </p>
              <p class="terms-desc">
                （2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
              </p>
              <p class="terms-desc">（3）涉及他人隐私、个人信息或资料的；</p>
              <p class="terms-desc">
                （4）发表、传送、传播骚扰、广告信息及垃圾信息；
              </p>
              <p class="terms-desc">（5）未经他人同意传递商业等信息；</p>
              <p class="terms-desc">
                （6）实施任何改变或试图改变昶联服务提供的系统配置或破坏系统安全的行为；利用技术或其他手段破坏、扰乱昶联服务的运营或他人对昶联服务的使用；以任何方式干扰或企图干扰昶联任何产品或任何部分、功能的正常运行，或者制作、发布、传播上述工具、方法等。
              </p>
              <p class="terms-desc">
                （7）其他违反法律法规、政策及公序良俗、社会公德或干扰本软件正常运营和侵犯其他用户或第三方合法权益内容的信息。
              </p>
              <p class="terms-desc">
                （8）在您进行账号注册或使用本服务时，如发现您账号可能存在涉诈、虚假注册等异常情形或风险的，昶联有权重新核验您的账号，并可根据风险情况，采取限期改正、限制功能、暂停使用、关闭账号、禁止重新注册以及本协议规定的其他处置措施。
              </p>
              <p class="terms-desc">
                （9）在向您提供服务过程中，如发现涉诈、冒名顶替等违法犯罪线索、风险信息的，昶联有权依照国家有关规定移送公安、金融、电信、网信等主管部门。
              </p>
              <p class="terms-desc">
                3.3.4
                您理解并同意昶联将会尽其商业上的合理努力保障您在本平台及服务中的数据存储安全，但是，昶联并不能就此提供完全保证，包括但不限于以下情形：
              </p>
              <p class="terms-desc">
                （1）昶联不对您在本平台及服务中相关数据的删除或储存失败负责；
              </p>
              <p class="terms-desc">
                （2）昶联有权根据实际情况自行决定单个用户在本软件及服务中数据的最长存储期限，并在服务器上为其分配数据最大存储空间等。
              </p>
              <p class="terms-desc">
                （3）如果您停止使用本平台及服务或服务被终止或取消，昶联可以从服务器上永久地删除您的数据。服务停止、终止或取消后，腾讯没有义务向您返还任何数据。
              </p>
              <p class="terms-desc">
                （4）在您进行账号注册或使用本服务时，如发现您账号可能存在涉诈、虚假注册等异常情形或风险的，昶联有权重新核验您的账号，并可根据风险情况，采取限期改正、限制功能、暂停使用、关闭账号、禁止重新注册以及本协议规定的其他处置措施。
              </p>
              <p class="terms-desc">
                （5）在向您提供服务过程中，如发现涉诈、冒名顶替等违法犯罪线索、风险信息的，昶联有权依照国家有关规定移送公安、金融、电信、网信等主管部门。
              </p>
            </strong>
            <p class="terms-desc">
              3.3.5
              您应采取有效措施，防止黑客攻击、盗用密码、非法调用页面等危害通信业务信息安全的非法行为。您应妥善保管使用本服务的接口、账号密码并保证自用终端和网络的安全性，使用账号密码从事的一切行为均视为您的行为，因账号密码丢失或被盗以及网络不安全产生的后果由您自行承担。
            </p>
            <p class="terms-desc">
              3.3.6
              您保证不得以任何方式或企图修改或干扰平台或其他昶联网站及系统以及昶联提供给您的网站、系统或代码的任何部分、功能。您违反上述条款，昶联有权根据其情节，对您实施警告、限制服务、应用下线、应用删除、中止或终止服务等措施。
            </p>
            <strong>
              <p class="terms-desc">
                3.4 您需要充分、完整阅读《昶联隐私政策》。
              </p>
              <p class="terms-desc">
                3.4.1
                您需制定针对您终端用户的隐私政策，且不得低于我们的隐私保护标准，您的隐私政策应当实质包含以下条款：我们的某些服务由第三方合作伙伴提供。如推送服务，在提供该等服务的过程中，第三方合作伙伴需知晓您的相关个人信息，即IP地址、设备标识符、位置信息、软件列表，以保证数据统计的准确性；又如短信/语音业务，个人信息则包括手机号码，具体详见隐私政策内容。为向您提供更好的服务、改善技术和提升用户体验，您理解并同意，我们有权在必要范围内向第三方合作伙伴提供您的个人信息。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，并且只会共享提供服务所必要的信息。我们的合作伙伴无权将共享的信息用于任何其他用途。当我们要将个人信息用于本协议未载明的其他用途时，会事先通知您并征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，也会事先通知您并征求您的同意。
              </p>
              <p class="terms-desc">
                3.4.2
                若您的终端用户撤回上述授权及许可，您应当采取必要的技术手段，停止为终端用户提供我们的服务，并立即书面通知我们。
              </p>
            </strong>
            <p class="terms-desc">3.5 第三方产品或服务</p>
            <p class="terms-desc">
              3.5.1如果您通过昶联获取、使用第三方提供的任何产品或服务（包括但不限于平台服务市场中由服务商等第三方提供的产品、服务），您应当自行评估该产品或服务是否符合您要求。
            </p>
            <p class="terms-desc">
              3.5.2第三方产品或服务的开通，可能需要您与第三方另行签订单独的协议，单独的协议可能以电子文档形式展示，也可能是独立的纸质文档，您可以根据自身情况决定是否接受协议及使用产品或服务。
            </p>
            <p class="terms-desc">
              3.5.3您因使用第三方产品或服务产生的纠纷由您与第三方自行解决，涉及的经济赔偿由您自行承担。
            </p>

            <p class="terms-desc"><strong>第四条 平台权利义务</strong></p>
            <p class="terms-desc">
              4.1 平台如遇网络或系统维护或升级，需要暂停服务或要求您做出配合；
            </p>
            <p class="terms-desc">
              4.2 昶联向您提供可以实现统计查询、账单、详细话单等功能服务；
            </p>
            <p class="terms-desc">
              4.3
              昶联有权对平台进行修改、升级等各项操作，并有权制定并修改平台及服务规范，规范一经发布在昶联及其关联公司网站上即构成本协议的一部分，您应当予以遵守，否则您应当停止使用本服务，本协议自动终止。
            </p>
            <p class="terms-desc">
              4.4
              昶联有权为预防、发现和调查欺诈、危害安全、非法或违反与您或其关联公司协议、政策或规则的行为对您的服务进行检查监督。昶联有权根据最终用户或第三方的投诉以及昶联对您服务的监督检查，自行决定您及其客户是否利用本服务从事不正当活动，并以此中止或终止向您提供服务。
            </p>
            <p class="terms-desc">
              4.5
              昶联有权在必要时变更部分或全部的服务功能，如功能变更可能引起日常服务的中断或终止，昶联应当通知您，并尽快完成基本功能的修复。您可以选择继续使用调整后的服务功能，或终止使用平台服务。
            </p>
            <p class="terms-desc">
              4.6
              昶联有权根据中国法律法规的调整、行政执法机关的命令和社会伦理道德的变化调整平台服务的标准。
            </p>
            <p class="terms-desc">
              4.7
              在适用法律所允许的最大范围内，平台有权对整个平台用户数据库进行分析并将用户数据库用于其他用途，包括但不限于对用户数据进行用户行为分析以及一系列的用户数据挖掘和数据深度分析。
            </p>
            <p class="terms-desc">
              4.8
              平台有权在必要时变更部分或全部的服务功能，如功能变更可能引起日常服务的中断或终止，昶联应当在变更一个月之前事先通知您，并尽快完成基本功能的修复。您可以选择继续使用调整后的服务功能，或终止使用平台服务。
            </p>
            <p class="terms-desc">
              4.9
              您的应用及您所从事的经营业务不得对昶联及其关联公司的利益构成任何现实或潜在的损害或冲突，否则昶联有权立即通知您并终止平台向使用者提供的所有服务，而无需承担任何法律责任和费用补偿，同时昶联保留追究您相应责任的权利。
            </p>

            <p class="terms-desc">4.10收费服务</p>
            <p class="terms-desc">
              4.10.1
              昶联将根据经营的需要，向用户提供与本服务相关的免费或收费服务。本平台有权将本服务的部分或全部调整为收费服务，并有权制定收费标准、收费方式等规则。您可以接受或拒绝收费服务，如果您选择使用收费服务，需要支付费用；如果您拒绝支付费用，本平台有权停止向您提供服务。
            </p>
            <p class="terms-desc">
              4.10.2
              您所享有的本服务的具体内容可能会因为您选择的具体服务类别等因素而有不同，具体以相关服务页面公布、实际提供的内容为准。
            </p>
            <p class="terms-desc">
              4.10.3
              您理解并同意：昶联有权对本服务进行调整，并可能会对您已享有或正在享有的权益造成影响，您同意按照调整后的内容进行使用，且不要求昶联承担任何责任。
            </p>
            <p class="terms-desc">
              <strong
                >4.10.4
                您理解并明确同意：您已支付的服务费用应当在服务期限内或已订购的订阅包（套餐包）中的服务期内被使用完毕（以前述二者早发生为准）。服务期限届满前，若您无理由擅自退订相应产品/服务，昶联有权采取恢复官网价格计费、收取违约金、收取退订/退款手续费或不退费等方式进行处理。您应在服务期限内将已支付的服务费使用完毕，如服务期限届满，您已支付但未使用完毕的服务将被作废且昶联将不提供其他替代或补充。
              </strong>
            </p>

            <p class="terms-desc"><strong>第五条 保密条款</strong></p>
            <p class="terms-desc">
              5.1
              本协议所称保密信息，是指一方（以下简称“接受方”）从对方（以下简称“披露方”）取得的、获知的或因双方履行本协议而共同创造且具有不可分割性的商业秘密，电脑程序，设计技术，想法，专有技术，工艺，数据，业务和产品开发计划，与该披露方业务有关的客户信息及其他信息，或该披露方从他方收到的保密信息。无论上述信息和资料以何种形式或载于何种载体，无论披露方在披露时是否以口头、图像或书面等方式表明其具有保密性。
            </p>
            <p class="terms-desc">
              5.2
              接收方与披露方双方应采取适当措施妥善保存对方提供的保密信息，措施的审慎程度不少于其保护自身的保密信息时的审慎程度。接收方与披露方仅能将保密信息用于与本协议项下的有关用途或目的。
            </p>
            <p class="terms-desc">
              5.3
              双方保证保密信息仅可在各自一方从事该业务的负责人和雇员范围内知悉。在双方上述人员知悉该保密信息前，应向其提示保密信息的保密性和应承担的义务，并以可证明的方式表明上述人员确实承担本协议项下的保密责任。
            </p>
            <p class="terms-desc">
              5.4
              如确有必要，接受方应按照保密信息披露方的指示将含有保密资料的所有文件或其他资料归还给披露方，或按其指示予以销毁。
            </p>
            <p class="terms-desc">5.5 本条上述限制条款不适用于以下情况：</p>
            <p class="terms-desc">
              · 在签署本协议之时或之前，该保密信息已以合法方式属接受方所有；
            </p>
            <p class="terms-desc">
              · 保密信息在通知给接受方时，已经公开或能从公开领域获得；
            </p>
            <p class="terms-desc">
              · 保密信息是接受方从与其没有保密或不透露义务的第三方获得的；
            </p>
            <p class="terms-desc">
              ·
              在不违反本协议项约定责任的前提下，该保密信息已经公开或能从公开领域获得；
            </p>
            <p class="terms-desc">
              ·
              该保密信息是接受方或其关联或附属公司独立开发，而且未从通知方或其关联或附属公司获得的信息中获益；
            </p>
            <p class="terms-desc">
              ·
              接受方应法院或其他法律、行政管理部门要求披露的信息（通过口头提问、询问、要求资料或文件、传唤、民事或刑事调查或其他程序）因而透露保密信息，在该种情况发生时，接受方应立即向披露方发出通知，并作出必要说明。
            </p>
            <p class="terms-desc">
              5.6 接收方与披露方亦对本协议的具体内容负有保密责任。
            </p>
            <p class="terms-desc">
              5.7
              接收方与披露方为了履行本协议而进行的沟通、通知、告知等文件传递或文件交换，应由双方妥善保存，不得用于不利于双方开展业务的目的。双方不得诋毁诽谤对方，亦不得在公开场合以攻击对方为目的发布不利于双方的言论。
            </p>

            <p class="terms-desc"><strong>第六条 知识产权</strong></p>
            <p class="terms-desc">
              6.1
              平台上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由昶联或其他权利人依法拥有其知识产权。非经昶联或其他权利人书面同意任何人不得擅自使用、修改、复制、公开传播、改编、散布、发行或公开发表平台程序或内容。
            </p>
            <p class="terms-desc">
              6.2
              未经昶联同意，您不得擅自使用您或其关联公司的商标、名称以及昶联相关商标及其标识。
            </p>
            <p class="terms-desc">
              6.3
              本协议项目合作涉及的系统、代码、数据、品牌等，均由双方各自依法拥有知识产权，未经权利拥有一方的书面同意，另一方不得擅自使用、修改、复制、公开传播、发行，否则，权利拥有一方有权立即终止本协议并要求对方赔偿全部损失（包括但不限于调查取证费用、公证费用、律师费等）。
            </p>
            <p class="terms-desc">
              6.4
              您不得将昶联基于本项目合作开放的技术接口、源代码及算法等进行反向工程、反汇编、重构、反编译、翻译、修改、复制，或者在未经明确允许的情况下创作衍生作品。
            </p>
            <strong>
              <p class="terms-desc">第七条 出口管制与制裁法律法规</p>
              <p class="terms-desc">
                各方承诺遵守所有适用的经济与贸易制裁以及出口管制法律法规，包括所有由联合国安全理事会、中国、美国及任何其他国家所制定并执行的制裁决议、法律与法规以及出口管制法律与法规（在前述文件适用于该方的范围内）（下称
                “适用出口管制法律”）。您承诺不会将昶联提供的产品或服务用于适用出口管制法律所禁止的用途。非经相关主管机关许可，您及您授权使用昶联提供的产品或服务的个人或实体不会通过昶联提供的产品或服务向所适用出口管制法律所制裁或指定的个人或实体提供受控的技术、软件或服务，或以任何方式使得昶联违反适用出口管制法律。
              </p>

              <p class="terms-desc">第八条 免责条款</p>
              <p class="terms-desc">
                8.1
                您充分理解，使用昶联的通信通道涉及因特网、电信运营商的网络服务，可能会受到各个环节不稳定因素的影响，因此您同意，对因停电、计费系统故障、传输线路故障、通信故障及通信线路故障、检修升级、计算机错误或病毒、黑客攻击、信息损坏、数据丢失或其他在昶联合理控制范围之外的原因所造成的中断、延迟或停顿等服务问题，昶联不承担责任；为防止发生意外，请您不要仅依靠网络作为重要情况下使用的唯一通信工具，对于您利用昶联服务开展活动而造成的损失，双方同意昶联不向您或任何第三方承担责任。
              </p>
              <p class="terms-desc">
                8.2
                昶联有权定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断或暂停，昶联无需为此承担任何责任。
              </p>
              <p class="terms-desc">
                8.3 如发生下述任一情况而导致服务中断及您损失的，昶联不承担责任：
              </p>
            </strong>
            <p class="terms-desc">
              <strong>· 发生不可抗力情形的；</strong
              >（因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，遭受不可抗力、意外事件的一方不承担责任。不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动乱、政府行为、电信主干线路中断、黑客、网络堵塞、电信部门技术调整和政府管制等。）
            </p>
            <strong>
              <p class="terms-desc">· 黑客攻击、计算机病毒侵入或发作的；</p>
              <p class="terms-desc">
                · 计算机系统遭到破坏、瘫痪或无法正常使用的；
              </p>
              <p class="terms-desc">· 因政府管制而造成中止或终止服务的；</p>
              <p class="terms-desc">· 其它非因昶联的过错而引起的。</p>
              <p class="terms-desc">
                8.4
                在任何情况下，昶联均不就因使用昶联平台提供的服务所发生的任何间接性、后果性、惩戒性、偶然性、特殊性的损害，包括您使用昶联平台服务而遭受的利润损失承担责任（即使您已事先被告知该等损害发生的可能性）。
              </p>
            </strong>
            <p class="terms-desc"><strong>第九条 协议的终止</strong></p>
            <p class="terms-desc">
              9.1
              如您违反本协议约定或平台规则、规范时，昶联有权随时暂停或终止向您提供平台服务。如您后续再直接或间接或以他人名义注册并登录平台的，昶联有权直接单方面暂停或终止提供本协议下服务。
            </p>
            <p class="terms-desc">
              9.2
              如本协议服务终止，昶联有权保留或删除您账号中的任何信息和全部相关数据，包括服务终止前您尚未完成的任何数据。
            </p>
            <p class="terms-desc">
              9.3
              因法律法规或政策原因导致平台业务无法继续运营的，双方合同自动解除，互不承担违约责任。
            </p>

            <p class="terms-desc"><strong>第十条 违约责任</strong></p>
            <p class="terms-desc">
              10.1
              如因您违反本协议约定使用我们平台提供的服务，包括但不限于使用任何方式侵害第三方知识产权，或使用任何包含淫秽、诽谤、中伤、谣言、种族主义或任何侵害他人名称权、名誉权或隐私权的材料或信息，由此引发第三人向我们提出索赔、诉讼或其他程序，您应对我们因此产生的全部损失（包括但不限于诉讼费、律师费、和解费、赔偿费在内的全部费用）进行赔偿，并使我们免受损害。
            </p>
            <p class="terms-desc">
              10.2
              如因您违反上述承诺导致您的终端用户对我们主张任何形式的索赔或权利要求，或导致我们被卷入到法律或行政程序，您将负责全面解决应诉、并为我们抗辩，或在我们的要求下合作抗辩，以保障我们的利益不受损害，并赔偿我们因此可能遭受的损失包括但不限于诉讼费、律师费、和解费、赔偿费在内的全部费用。
            </p>
            <p class="terms-desc">
              10.3
              如发现您违约，我们有权不经过事先通知而直接删除相关信息，终止、暂停为您提供服务并要求您立即停止继续使用、复制相关的文档。
            </p>
            <p class="terms-desc">
              10.4
              如发生下述任一情况，我们有权终止本协议或暂停我们的服务，并要求您立即删除涉及的算法、模型和数据或终止侵犯他人权益的行为：
            </p>
            <p class="terms-desc">
              ·
              您违反法律法规接入我们的平台、上传、发布信息或不正当使用我们的服务；
            </p>
            <p class="terms-desc">
              · 您的行为侵犯他人隐私、商业秘密或其他用户权益。
            </p>
            <p class="terms-desc">
              如发生上述情形，您应以自己的名义独立承担所有责任，并对遭受侵害的其他用户或任何第三方的损失进行赔偿。
            </p>
            <p class="terms-desc">
              10.5
              我们有权按照专业或非专业标准对您的行为进行违约审查，您同意并不可撤销地认可我们对您的审查结果以及收集的证据。我们根据本协议或相关协议对您的违约行为进行处理后，不免除您应承担的法律责任。
            </p>
            <p class="terms-desc">
              10.6
              您须对自己在使用昶联服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在昶联首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应给予昶联等额的赔偿。在任何情况下，昶联都不对您或任何第三方因本协议产生的任何间接性、后果性、偶然的、特殊或惩罚性的损害赔偿承担责任。
            </p>

            <p class="terms-desc"><strong>第十一条 争议解决</strong></p>
            <p class="terms-desc">
              本协议履行中若发生争议，双方应当本着互谅互让的原则，协商解决。协商不成的，任何一方均有权向宁波市鄞州区人民法院提起诉讼。
            </p>

            <p class="terms-desc">
              <strong>第十二条 协议的生效、续签及其他</strong>
            </p>
            <p class="terms-desc">
              12.1本协议内容包括协议正文及所有昶联已经发布的或将来可能发布的平台相关规则。平台有权在必要时修改本协议条款。昶联对本协议条款拥有解释权。您可以在本平台的最新版本中查阅相关协议条款。本协议条款变更后，如果您继续使用本平台提供的服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本平台提供的服务。
            </p>
            <p class="terms-desc">
              12.2
              双方未行使或未执行本协议任何权利或规定，不构成对前述权利之放弃。
            </p>
            <p class="terms-desc">
              12.3
              本协议项下昶联对使用者所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人
            </p>
            <p class="terms-desc">
              12.4
              若本协议中部分条款因任何原因而被认定无效，此种无效条款并不影响其他条款的有效性，且此种无效条款应自始视为不存在。
            </p>
            <p class="terms-desc">
              12.5
              昶联有权根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知您。
            </p>
            <p class="terms-desc">
              12.6
              协议终止后，有关知识产权、数据信息保密的条款不因协议的终止而终止。
            </p>
            <strong>
              <p class="terms-desc">联系我们：</p>
              <p class="terms-desc">
                如果您对本隐私权政策有任何疑问、意见或建议，您可以通过下述方式联系我们：邮箱：<a
                  href="javascript:;"
                  >legal@solutioncl.com，</a
                >电话：<a href="javascript:;">400-7800-900，</a
                >我们将在15天内回复您的请求。
              </p>
            </strong>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ZhTermsAndServices",
};
</script>
<style scoped>
.center-align {
  display: block;
  text-align: center;
}
.right-align {
  display: block;
  text-align: right;
}
:deep(strong > p) {
  font-weight: bold !important;
}
.li-img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>

  