<template>
  <!-- <HeaderEng :languageChangeFun="languageChange" v-if="isLangEng" /> -->
  <HeaderCh :languageChangeFun="languageChange" v-if="!isLangEng" />
  <router-view></router-view>
  <!-- <FooterEng :languageChangeFun="languageChange" v-if="isLangEng" /> -->
  <FooterCh :languageChangeFun="languageChange" v-if="!isLangEng" />
</template>

<script>
import $ from "jquery";
// import HeaderEng from "./HeaderEng.vue";
import HeaderCh from "./HeaderCh.vue";
// import FooterEng from "./FooterEng.vue";
import FooterCh from "./FooterCh.vue";
import i18n from "@/locale";

//不是zh开头的中文路由
// const zhPathList = [
//   "/document/"
// ]

//点击顶部标题之后，如果点击同一个，就要把当前页滑动到顶部
const sampPathNeedScrollTopList = [
  "/zh-industry-e-commerce",
  "/zh-industry-education",
  "/zh-industry-fintech",
  "/zh-industry-gaming",
  "/zh-industry-logistics",
  "/zh-industry-online-social",
  "/zh-industry-retail",
  "/industry-e-commerce",
  "/industry-education",
  "/industry-fintech",
  "/industry-gaming",
  "/industry-logistics",
  "/industry-online-social",
  "/industry-retail",
];

export default {
  name: "HeaderGlobal",
  components: {
    // HeaderEng,
    HeaderCh,
    // FooterEng,
    FooterCh,
  },
  data() {
    return {
      isLangEng: true,
    };
  },
  methods: {
    mobileHeaderInitToggleBtn() {
      // Main Submenu button Toggle in header
      $(document).click(function (event) {
        if ($(event.target).hasClass("toggle-menu")) {
          console.log("a");
          $(event.target).toggleClass("active");
          $("header").toggleClass("active");
          $("html, body").toggleClass("overflow-hidden");
        }
      });
    },
    addSubmenuItemsIconClick() {
      // Submenu icon click
      $(document).click(function (event) {
        if ($(event.target).hasClass("submenu_arrow")) {
          $(event.target).toggleClass("active");
          $(event.target)
            .closest(".has_submenu")
            .find(".submenu")
            .slideToggle();
        }
        if ($(event.target).hasClass("submenu_aWrap")) {
          $(event.target).next().toggleClass("active");
          $(event.target)
            .closest(".has_submenu")
            .find(".submenu")
            .slideToggle();
        }
      });
    },
    addNestedDropdownTrigger() {
      // Submenu icon click
      $(document).click(function (event) {
        if ($(event.target).hasClass("nested_dropdown_trigger")) {
          $(event.target).toggleClass("active");
          $(event.target)
            .closest(".col")
            .find(".nested_dropdown")
            .slideToggle();
        }
        if ($(event.target).hasClass("nested_dropdown_trigger_wrap")) {
          $(event.target).find(".nested_dropdown_trigger").toggleClass("active");
          $(event.target)
            .closest(".col")
            .find(".nested_dropdown")
            .slideToggle();
        }
      });
    },
    hideOpenedHeaderOnPageLoad() {
      //   hide opened menu for devices under 1200px
      if ($(window).width() < 1200) {
        if ($("header").hasClass("active")) {
          $("header").removeClass("active");
        }
        if ($("header .toggle-menu").hasClass("active")) {
          $("header .toggle-menu").removeClass("active");
        }
        if ($("header .submenu_arrow").hasClass("active")) {
          $("header .submenu_arrow").removeClass("active");
        }
        if ($("header .nested_dropdown_trigger").hasClass("active")) {
          $("header .nested_dropdown_trigger").removeClass("active");
        }
        if ($("html, body").hasClass("overflow-hidden")) {
          $("html, body").removeClass("overflow-hidden");
        }
        $("header .has_submenu .submenu").slideUp();
        $("header .nested_dropdown").slideUp();
      }
      if ($(window).width() > 1199.98) {
        $(".has_submenu>.megamenu_wrap").css("display", "none");
        setTimeout(() => {
          $(".has_submenu>.megamenu_wrap").css("display", "");
        }, 100);
      }
    },
    languageChange(language) {
      if (language === "EN") {
        this.isLangEng = true;
        localStorage.setItem("lang", "en-US");
        i18n.global.locale = 'en-US';
      } else {
        this.isLangEng = false;
        localStorage.setItem("lang", "zh-CN");
        i18n.global.locale = 'zh-CN';
      }
      this.changePageAccordingLang();
    },
    changePageAccordingLang() {
      let currPath = this.$route.path;
      let query = this.$route.query;
      let params = new URLSearchParams(query).toString();
      let currPathWithoutSlash = currPath.substring(1);
      let dynRoot = "";
      // Check if the current route has a path
      if (currPathWithoutSlash && currPathWithoutSlash != "zh") {
        // means it is not home page
        if (this.isLangEng == true) {
          if(currPathWithoutSlash.startsWith("document")) {
            window.location.href = "/document-en"
            return
          }
          dynRoot = currPathWithoutSlash.substring(3);
          if(params) {
            window.location.href = "/" + dynRoot + "?" + params;
          }else {
            window.location.href = "/" + dynRoot;
          }
        } else {
          if(currPathWithoutSlash.startsWith("document-en")) {
            window.location.href = "/document/sms"
            return
          }
          dynRoot = "/zh-" + currPathWithoutSlash;
          if(params) {
            window.location.href = dynRoot + "?" + params;
          }else {
            window.location.href = dynRoot;
          }
        }
      } else {
        // means it is home page
        if (this.isLangEng == true) {
          window.location.href = "/";
        } else {
          window.location.href = "/zh";
        }
      }
    },
    initLanguageHeader() {
      localStorage.setItem("lang", "zh-CN");
      this.isLangEng = false;
      i18n.global.locale = 'zh-CN';
      // console.log(this.$route)
      // let currPath = this.$route.path;
      // if(currPath.startsWith("/zh") || zhPathList.findIndex(el => currPath.startsWith(el)) !== -1) {
      //   localStorage.setItem("lang", "zh-CN");
      //   this.isLangEng = false;
      // }else {
      //   this.isLangEng = true;
      //   localStorage.setItem("lang", "en-US");
      // }
    },
  },
  created() {
    this.mobileHeaderInitToggleBtn();
    this.addSubmenuItemsIconClick();
    this.addNestedDropdownTrigger();
  },
  mounted() {
    this.hideOpenedHeaderOnPageLoad();
    this.$router.afterEach((to, from) => {
      this.hideOpenedHeaderOnPageLoad();
      this.initLanguageHeader();
      // console.log("dsadsa323223232", to, from)
      if(sampPathNeedScrollTopList.includes(to.fullPath) && to.fullPath === from.fullPath) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    });
  },
};
</script>
