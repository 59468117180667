<template>
  <main>
    <section class="terms">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="terms-hdng center-align">昶联隐私政策</h1>
            <p class="terms-desc term-days right-align">
              最新版本生效时间：2024年8月1日
            </p>

            <h2 class="terms-list-hdng privacy-hdng center-align">引言</h2>
            <p class="terms-desc indent">
              欢迎您使用由宁波昶联软件有限公司（以下简称“昶联”或“我们”）及其关联公司在昶联平台（以下简称“本平台”）上提供的产品和/或服务，我们的注册地址为【浙江省宁波市鄞州区邱隘镇沈家村晨源大楼8-02-146室】。
            </p>
            <p class="terms-desc indent">
              您和/或您的终端用户信任对我们非常重要我们会尽全力为您和/或您的终端用户提供安全的网络环境，同时恪守以下原则，保护您和/或您的终端用户的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则。我们依据《中华人民共和国网络安全法》《中华人民共和国数据安全法》《中华人民共和国个人信息保护法》以及互联网行业关于信息保护的法律法规，并参考《信息安全技术个人信息安全规范》（GB/T
              35273-2020）等推荐性国家标准保护您和/或您的终端用户的个人信息。本隐私保护政策将向您阐述我们如何收集、使用您的相关信息并帮助您了解您的企业的权利，请您在使用昶联平台的服务前，仔细阅读并了解本隐私政策。请尤其注意本隐私政策中加粗的内容并确定了解我们对您和/或您的终端用户信息的处理规则。
            </p>
            <p class="terms-desc indent">
              在注册、浏览或使用昶联服务前，您以勾选弹窗等任何主动选择的方式确认接受本政策或继续使用昶联服务，即视为您已审慎完成阅读并充分理解以及接受本条款的全部内容，本政策即在您和昶联之间产生法律效力，特别是限制或者免除责任的条款（包括管辖条款），该类条款将以加粗和/或加下划线等显著形式提示您注意。本政策适用于昶联提供的各项服务。
            </p>
            <p class="terms-desc indent">
              如果您对本条款或相关事宜有任何问题，请通过<a href="javascript:;"
                >legal@solutioncl.com</a
              >（邮箱）与我们联系
            </p>
            <h2 class="terms-list-hdng privacy-hdng">定义</h2>
            <p class="terms-desc">
              <strong>昶联网站：</strong>指昶联官网（域名为：<a
                href="javascript:;"
                >www.solutioncl.com</a
              >）。
            </p>
            <p class="terms-desc">
              <strong>昶联：</strong
              >除非另有约定，指宁波昶联软件有限公司（注册地址：浙江省宁波市鄞州区邱隘镇沈家村晨源大楼8-02-146室），昶联是<a
                href="javascript:;"
                >www.solutioncl.com</a
              >以及昶联平台的经营者。
            </p>
            <p class="terms-desc">
              <strong>昶联的关联公司：</strong
              >指昶联国内总公司，各分、子公司及海外分、子公司。
            </p>
            <p class="terms-desc">
              <strong>用户或您：</strong>指使用昶联产品和/或服务的个人或者企业
            </p>
            <p class="terms-desc">
              <strong>个人信息：</strong
              >指以电子或者其他方式记录的、能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
              本政策所指个人信息请见“我们如何收集和使用您的个人信息”这一条款中的所一一列明的类型。未免疑义，个人信息包括但不限于个人敏感信息。
            </p>
            <p class="terms-desc">
              <strong>个人敏感信息：</strong
              >指一旦泄露、非法使用，容易导致人格尊严受到侵害或者人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。
              本政策中涉及的个人敏感信息可能包括：您的财产信息（包括银行账号及充值信息等虚拟财产信息）；个人身份信息（包括身份证）；网络身份识别信息（包括账户名、账户昵称、邮箱地址及与前述有关的密码）；
              其他信息（包括通讯录、个人电话号码、手机号码、行程信息、网页浏览记录、精准定位信息）。对于本政策中列示的如上个人敏感信息，为便于您在阅读时有所警觉，我们除在此统一定义并列示外，仍然会在本政策中以字体加粗、加下划线，或单独指出等突出显示的方式再行提示您注意。
            </p>
            <p class="terms-desc">
              <strong>非个人信息：</strong
              >指不能识别或关联个人的信息。包括匿名化信息（不可复原），以及作为企业中已由相关主管部门公示披露的全部非个人信息（例如企业联系地址、联系方式、营业年限、企业规模、从事行业、主营业务、主要产品、网站域名等）。
            </p>
            <p class="terms-desc">
              <strong>去标识化：</strong
              >指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或者关联您的过程
            </p>
            <p class="terms-desc">
              <strong>业务数据：</strong
              >不同于用户信息，是指昶联的用户利用昶联平台的服务上传、下载、分发等通过昶联的技术服务处理的数据。
            </p>
            <h2 class="terms-list-hdng privacy-hdng">法律声明</h2>
            <p class="terms-desc"><strong>一、权利归属</strong></p>
            <p class="terms-desc">
              1.1. 昶联网站的 Logo
              、“昶联”等文字、图形及其组合，以及昶联网站的其他标识、徽记、昶联服务的名称等为昶联及其关联公司在中国的注册商标。未经昶联书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您/您的企业有权展示、使用或做其他处理。
            </p>
            <p class="terms-desc">
              1.2.
              昶联网站及昶联平台所有的产品、服务、技术与所有程序（以下或简称“技术服务”）的知识产权均归属于昶联或归其权利人所有。
            </p>
            <p class="terms-desc">
              1.3.
              除非昶联另行声明，昶联拥有昶联在网站内发布文档等信息（包括但不限于文字、图形、图片、照片、音频、视频、图标、色彩、版面设计、电子文档）的所有权利（包括但不限于版权、商标权、专利权、商业秘密和其他所有相关权利）。未经昶联许可，任何人不得擅自使用如上内容（包括但不限于通过程序或设备监视、复制、传播、展示、镜像、上传、下载昶联网站内的任何内容）。被授权浏览、复制、打印和传播属于昶联网站内信息内容的，该等内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此权利声明。
            </p>
            <p class="terms-desc"><strong>二、责任限制</strong></p>
            <p class="terms-desc">
              2.1
              昶联用户在昶联网站上，自行上传、提供、发布相关信息，包括但不限于用户名称、公司名称、
              联系人及联络信息，相关图片、资讯等，该等信息均由用户自行提供，昶联的用户须对其提供的任何信息依法承担全部责任。
            </p>
            <p class="terms-desc">
              <strong
                >2.2
                昶联在此提示，您和/或您的终端用户在使用昶联服务期间应当遵守中华人民共和国的法律，不得危害网络安全，不得利用昶联的服务从事他人侵犯名誉、隐私、知识产权和其他合法权益的活动。尽管有前述提示，昶联不对您和/或您的终端用户使用昶联服务的用途和目的承担任何责任。
              </strong>
            </p>
            <p class="terms-desc"><strong>三、知识产权保护</strong></p>
            <p class="terms-desc">
              我们尊重知识产权，反对并打击侵犯知识产权的行为。任何组织或个人认为昶联网站的网页（含
              <a href="javascript:;">www.solutioncl.com</a
              >）内容（如转载文章等）可能侵犯其合法权益的，可以通过向昶联<a
                href="javascript:;"
                >legal@solutioncl.com</a
              >提出书面权利通知，昶联将在收到知识产权权利人合格通知后依法尽快处理。
            </p>
            <h2 class="terms-list-hdng privacy-hdng">隐私政策</h2>
            <p class="terms-desc">本隐私政策主要包含如下内容：</p>

            <strong><p class="terms-desc">一、我们如何收集和使用用户信息</p>
            <p class="terms-desc">二、Cookie和同类技术</p>
            <p class="terms-desc">三、我们如何共享、转让、公开披露用户信息</p>
            <p class="terms-desc">四、用户的权利</p>
            <p class="terms-desc">五、我们如何保护和保存用户信息</p>
            <p class="terms-desc">六、终端用户的授权与同意</p>
            <p class="terms-desc">七、未成年人隐私保护</p>
            <p class="terms-desc">八、本协议如何更新</p>
            <p class="terms-desc">九、本隐私政策适用范围</p>
            <p class="terms-desc">十、联系我们</p>

            <p class="terms-desc">一、我们如何收集和使用用户信息</p>

            <p class="terms-desc">1.1收集和使用用户信息的类型</p>

            <p class="terms-desc">
              1.1.1
              在用户使用昶联平台及服务时，我们会根据合法、正当、必要的原则，出于本政策所述的目的，收集和使用用户在使用昶联平台服务过程中主动提供的、因用户使用昶联平台及服务而产生的信息。
            </p>

            <p class="terms-desc">
              1.1.2为完成昶联平台账户的注册、登录、管理和实名认证等必要活动，用户需要提交真实、合法、有效的信息。需要提交的信息包括但不限于：用户个人的基本信息（名称、电子邮箱等），用户的企业基本信息（单位名称、营业执照、统一社会信用代码）、用户的法定代表人姓名、用户的经办人信息（包括姓名、邮箱地址、身份证号码）以及用户的银行账户信息（包括开户银行、开户账号、开户名称、注册地址及注册电话）；为实现实名认证的目的，用户同意并授权我们可能自行或委托第三方向有关身份认证机构（如个人征信机构、政府部门等）提供、查询、核对用户的前述信息。
            </p>

            <p class="terms-desc">1.2 为用户提供注册、认证服务</p>

            <p class="terms-desc">
              1.2.1
              当用户注册、登录昶联平台时，用户可以通过邮箱创建账号，我们将通过发送验证码来验证用户的身份是否有效。用户可以主动完善相关的网络身份识别信息（如密码、邮箱），收集这些信息是为了帮助用户完成注册。用户提交的手机号码及邮箱用于用户注册、登录、绑定账户、密码找回时接收验证码，并且作为用户与昶联指定的联系方式之一，用来接收相关业务通知（如新品上线、服务变更等），并且昶联可通过此联系方式向用户营销推广、宣传产品、发送业务通知（含账单）或与用户进行业务沟通。
            </p>
            </strong>
            <p class="terms-desc">
              1.2.2
              如果用户仅需使用昶联网站的浏览等基本服务，用户不需要注册成为我们的会员及提供上述信息。
            </p>
            <strong>
            <p class="terms-desc">1.3 向用户提供技术服务</p>
            <p class="terms-desc">在用户使用服务过程中我们会收集以下的信息：</p>
            <p class="terms-desc">
              1.3.1
              日志信息：为保障昶联平台的正常运行，保护用户或第三方的人身财产安全免遭侵害，当用户使用昶联平台及相关服务时，为了保障软件与服务的正常运行，我们会收集用户的设备型号、操作系统版本号、设备识别符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，iOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、软件版本号、登录IP地址、接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志、设备MAC地址、存储和电话权限、软件列表信息。请用户了解，这些信息是我们提供服务和保障服务正常运行所必须收集的基本信息。
            </p>
            <p class="terms-desc">
              1.3.2我们收集这些信息是为了向用户更好地提供服务，为了实现这一目的，我们还将把用户的信息用于下列用途：（1）联系用户解决问题；（2）改进我们的服务质量；（3）经用户许可的其他用途。
            </p>
            </strong>
            <p class="terms-desc">
              1.3.3
              用户账户的支持信息：基于用户使用昶联服务而产生的用户的咨询记录、保障记录和针对用户故障的排障过程（如通信或通话记录），昶联将通过记录、分析这些信息以便更及时响应用户的帮助请求，以及用于改进服务。
            </p>
            <p class="terms-desc">
              1.3.4
              我们在向用户提供业务功能或具体服务时，我们会按照本政策以及相应的产品服务协议的约定收集、使用、存储、保护用户信息；超出本政策以及相应的产品服务协议约定收集用户信息的，我们会另行向用户说明信息收集的范围与目的，并征得用户的同意后方收集提供相应服务所必要的用户的信息；如用户选择不提供前述信息，将会影响到用户使用相应产品，但不会影响用户使用昶联网站基本功能和其他产品。
            </p>
            <p class="terms-desc"><strong>1.4我们从第三方获取的您的个人信息</strong></p>
            <p class="terms-desc">
              1.4.1我们可能会在用户的授权同意范围内从第三方（我们的合作方）处收集并使用用户的个人信息。我们保证严格依照与第三方签订的合同以及相关法律规定处理用户的个人信息，同时请用户详细阅读该第三方的隐私政策及用户协议。如用户拒绝第三方在提供服务时收集、使用或者传递用户的个人信息，将可能导致用户无法使用昶联相应的服务。
            </p>
            <p class="terms-desc">
              1.4.2.
              我们可能通过网页等公开途径获得、适当使用有关用户的公开信息，或者从第三方提供商处获取有关用户企业已经对外公示的经营性信息，以帮助我们更好地了解我们的客户群，例如用户的行业、用户企业的规模和用户企业的网站URL。
            </p>
            <p class="terms-desc"><strong>1.5 为用户提供安全保障</strong></p>

            <p class="terms-desc">
              为提高用户使用我们服务的安全性，保护用户或公众的人身财产安全免遭侵害，我们使用或整合用户的注册信息、设备信息、网络日志，以及用户使用应用程序的频率、崩溃数据、总体使用情况、性能数据以及应用程序的来源，来综合判断用户账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
            </p>

            <p class="terms-desc"><strong>1.6其他目的</strong></p>

            <p class="terms-desc">
              在如下情形时，我们会根据法律法规的规定，另行事先征求用户的同意：
            </p>

            <p class="terms-desc">
              1.6.1 我们将信息用于本协议未载明的其他使用目的、用途；
            </p>

            <p class="terms-desc">
              1.6.2 我们将基于特定使用目的收集而来的信息用于其他使用目的。
            </p>

            <p class="terms-desc">
              1.7
              我们在提供产品和/或服务过程中，可能需要向用户申请部分设备系统权限，这些权限均不会默认开启，只有经过用户的明示授权才会在为实现特定产品功能和/或服务时使用，用户也可以撤回授权。特别需要指出的是，即使经过用户的授权，我们获得了相关设备系统权限，也不会在相关功能或服务不需要时而收集用户的信息。
            </p>

            <p class="terms-desc"><strong>二、Cookie和同类技术</strong></p>

            <p class="terms-desc">
              2.1
              Cookie和同类技术是互联网中的常用技术。为确保昶联平台正常运转、使用户获得更轻松的访问体验、向用户推荐可能感兴趣的内容或广告等，我们会在用户的移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie和同类技术，网站及客户端能够存储用户的注册账号、邮箱、密码（加密）和偏好等数据，省去重复填写个人信息，还可以帮助我们统计流量信息，分析页面设计和广告的有效性。
            </p>

            <p class="terms-desc">
              2.2
              我们不会将Cookie和同类技术用于本协议所述目的之外的任何用途。当然用户也可以自行选择清除移动设备上保存的所有Cookie（我的－设置－清理缓存）。
            </p>

            <p class="terms-desc">
              2.3 除 Cookie
              外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向用户发送的电子邮件可能含有链接至我们网站内容的地址链接，如果用户点击该链接，我们则会跟踪此次点击，帮助我们了解用户的产品或服务偏好以便于我们主动改善服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果用户不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
            </p>

            <p class="terms-desc"><strong>三、我们如何共享、转让、公开披露用户信息</strong></p>

            <p class="terms-desc">
              3.1 我们不会将用户的信息向第三方共享，但以下情况除外：
            </p>

            <p class="terms-desc">
              <strong>3.1.1
              在获取用户明确同意情况下的共享。</strong>在向用户告知第三方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，且获得用户的明确同意后，昶联会向用户同意的第三方共享用户授权范围内的信息。
            </p>

            <p class="terms-desc">
              <strong>3.1.2
              履行用户与我们签署的相关协议（含协议及平台规则）及其他法律文书所必须共享的个人信息；</strong>
            </p>

            <p class="terms-desc">
              <strong>3.1.2
              在法定情形下的共享。</strong>我们会根据法律法规规定、争议解决需要，或按行政、司法机关依法提出的要求，对外共享用户的信息。例如如果我们确定用户出现违反法律法规或严重违反昶联平台相关协议规则的情况，或为保护昶联平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或平台相关协议规则披露关于用户的信息；
            </p>

            <p class="terms-desc">
             <strong> 3.1.4
              我们可能会将用户的信息向我们的关联方共享，用户的信息可能会在我们的关联公司之间共享。我们只会共享必要的信息，且这种共享受本声明目的的约束。关联公司如要改变用户信息的处理目的，将再次征求用户的授权同意。并且，在将信息提供给关联公司前，我们将尽商业上的合理努力评估该关联公司所收集信息的合法性、正当性和必要性。我们会尽力要求关联公司对用户的信息采取保护措施。
             </strong>
            </p>

            <p class="terms-desc">
              <strong>3.1.5
              为提供服务之必要，共享给业务合作伙伴。</strong>我们可能会向合作伙伴等第三方共享用户的信息、账号信息、设备信息，以保障为用户提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享用户的信息，并且只会共享提供服务所必需的信息。为了保障用户的信息安全和隐私，我们通过协议约定明确了合作伙伴对用户信息的保护责任、义务和要求。合作方可能有其独立的隐私政策和用户协议，我们建议用户阅读并遵守第三方的用户协议及隐私政策。我们的合作伙伴包括：
              第三方合作伙伴，如服务供应商。为提供服务之必要，我们可能会将用户的账号信息、联系方式、必要的交易信息和交易纠纷信息共享给第三方合作伙伴来实现用户的需求。例如，①用户在使用昶联平台的短信或语音业务时，我们须与第三方合作伙伴共享用户的信息，交易才能完成。②当我们对用户进行身份认证的时候，我们会使用第三方认证机构的认证服务进行身份认证，为此，我们会基于用户的同意将用户的姓名和身份证号码提供第三方认证机构，以完成该等认证服务。
            </p>

            <p class="terms-desc">
              3.1.6
              当昶联平台发生合并、收购或破产清算情形时，如涉及用户信息转让，我们会要求继受方继续受本协议的约束，否则我们将要求该公司、组织和个人重新向用户征求授权同意。
            </p>
            <strong>
            <p class="terms-desc">
              3.2用户充分知晓，依据可适用的法律（如《中华人民共和国个人信息保护法》），在以下情形中，我们收集、使用您的个人信息无需征得您的授权同意：
            </p>

            <p class="terms-desc">3.2.1 涉及国家安全利益、社会公共利益的；</p>
            <p class="terms-desc">3.2.2 为履行法定职责或者法定义务所必需的；</p>
            <p class="terms-desc">
              3.2.3 为订立、履行您作为一方当事人的合同所必需的；
            </p>
            <p class="terms-desc">
              3.2.4
              为应对突发公共卫生事件，或者紧急情况下为保护您和他人的生命健康和财产安全所必需；
            </p>
            <p class="terms-desc">
              3.2.5
              在合理的范围内处理您自行向社会公众公开的或者其他已经合法公开的个人信息；
            </p>
            <p class="terms-desc">
              3.2.6
              在合理的范围内处理从合法公开披露的信息中收集的您的个人信息（如合法的新闻报道、政府信息公开等渠道）；
            </p>
            <p class="terms-desc">3.2.7 其他法律法规等规定的情形。</p>

            <p class="terms-desc">
              3.3
              根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
            </p>

            <p class="terms-desc">4. 有关敏感个人信息的提示</p>
            </strong>
            <p class="terms-desc">
              敏感个人信息，是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。以上由用户提供或我们收集用户的信息中，可能包含的敏感个人信息，例如身份证件号码、个人生物识别信息（静态或动态）、银行账号。请用户谨慎并留意敏感个人信息，用户同意我们可以按本政策所述的目的和方式来处理敏感个人信息。
            </p>

            <p class="terms-desc"><strong>四、用户的权利</strong></p>

            <p class="terms-desc">4.1 访问、更改、删除用户的账号信息</p>
            <p class="terms-desc">
              当用户完成账号的注册、登录并进行必要的身份验证后，用户有权通过联系客服的方式访问、更正、删除用户的账号信息。
            </p>

            <p class="terms-desc">
              注销账户：我们向用户提供账号注销的途径。在符合我们的服务条款约定条件及相关法律法规规定的情况下，用户可以选择联系我们的在线客服（<a
                href="javascript:;"
                >400-7800-900</a>）或者通过本政策“联系我们”章节所列的反馈渠道联系我们以注销用户的昶联账户。在用户注销账号时，我们可能会要求用户进行身份验证，以保障信息安全。用户应该知道，注销用户的昶联账户将导致用户永久失去对账户和账户中数据的访问权。我们将在验证用户的身份并与用户协商处理完用户账户中的资产后，为用户提供账户注销服务。为了向用户提供更加便捷的注销方式，我们后续会不断优化我们的产品，并且通过页面公告向用户告知。在注销昶联账户后，我们将停止为用户提供产品或服务，并依据用户的要求，尽快删除用户的个人信息或将其作匿名化处理，法律法规另有规定的除外。
            </p>

            <p class="terms-desc">
              4.2
              请您理解，每个业务功能都需要一些基本的用户信息才能得以完成，当用户撤回同意或授权后，我们无法继续为用户提供撤回同意或授权所对应的服务，也不再处理用户相应的个人信息。但用户撤回同意或授权的决定，不会影响此前基于用户的授权而开展的信息处理。
            </p>

            <p class="terms-desc">
              4.3
              如用户发现我们违反法律法规的规定或者双方的约定收集、使用用户的信息，用户可以要求我们删除。如用户发现我们收集、存储的用户的信息有错误的，且无法自行更正的，用户也可以要求我们更正。在删除相关信息时，我们可能会要求用户进行身份验证，以保障账户安全。以上删除请求一旦被响应，除法律法规另有规定要求保留的信息外，用户的个人信息将被删除。
            </p>

            <p class="terms-desc">
              4.4
              基于法律法规要求、保障信息安全等正当事由，用户的部分信息可能无法访问、修改和删除。
            </p>

            <p class="terms-desc">4.5获得个人信息副本</p>
            <p class="terms-desc">
              用户有权获取用户的个人信息副本，用户可以随时发送电子邮件至<a
                href="javascript:;"
                >legal@solutioncl.com</a>联系我们。在技术可行的前提下，例如数据接口匹配，我们还可按用户的要求，直接将用户的个人信息副本传输给用户指定的第三方。
            </p>

            <p class="terms-desc">4.6 响应用户的上述请求</p>
            <p class="terms-desc">
              为保障安全，我们可能会要求先验证用户的身份，再处理用户的请求。用户可能需要提供书面请求，或以其他方式证明用户的身份。对于用户提出的本节项下的所有请求，我们原则上将于收到用户的请求并在验证用户身份后的15日内进行反馈。
            </p>
            <strong>
            <p class="terms-desc">4.7 向我们进行投诉举报的权利</p>

            <p class="terms-desc">
              4.7.1
              当用户认为用户的信息可能受到侵害，可以点击“在线客服”或通过邮箱<a
                href="javascript:;"
                >legal@solutioncl.com</a>与我们联系。我们将尽快审核所涉问题，并在验证用户身份后及时予以回复，一般在15个工作日内做出答复。
            </p>

            <p class="terms-desc">4.8 查看隐私政策</p>

            <p class="terms-desc">
              4.8.1
              我们将在注册/登录昶联平台账号页面向您提示本隐私政策。此外，您也可以在昶联平台网站（<a
                href="javascript:;"
                >www.solutioncl.com</a>）首页尾部“关于我们”通过点击“用户隐私政策”随时查看本隐私政策的全部内容。
            </p>

            <p class="terms-desc">4.9 获知停止运营的权利</p>

            <p class="terms-desc">
              如果昶联平台终止服务或运营，我们将及时停止收集用户信息的活动，并且会至少提前三十日将停止运营的通知以逐一送达或公告的形式通知用户，并在终止服务或运营后对所持有的用户信息进行删除或匿名化处理。
            </p>

            <p class="terms-desc">五、我们如何保护和保存用户信息</p>

            <p class="terms-desc">5.1 技术保护措施</p>
            </strong>
            <p class="terms-desc">
              5.1.1
              我们非常重视用户信息的安全，将努力采取各种符合业界标准的合理的安全措施来保护用户的信息，使用户的信息不会被泄露、毁损或者丢失，包括但不限于SSL、隐私信息加密存储、数据中心的访问控制。我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息。
            </p>

            <p class="terms-desc"><strong>5.2 安全管理体系</strong></p>

            <p class="terms-desc">
              5.2.1
              我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术、个人信息安全影响评估等方面多维度提升整个系统的安全性。
            </p>

            <p class="terms-desc">
              5.2.2
              我们对可能接触到用户的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我们会举办安全和隐私保护培训课程，加强员工对于用户信息重要性的认识。
            </p>

            <p class="terms-desc"><strong>5.3 账号保护</strong></p>

            <p class="terms-desc">
              5.3.1
              用户的账户均有安全保护功能，请妥善保管用户的账户及密码信息。如果用户发现账号被盗用或信息泄露，请及时联系我们，以便我们采取相应措施。
            </p>

            <p class="terms-desc">
              5.3.2
              请使用复杂密码，协助我们保证用户的账号安全。我们将尽力保障用户发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致用户的合法权益受损，我们将承担相应的法律责任。
            </p>

            <p class="terms-desc"><strong>5.4 信息安全事件处理</strong></p>

            <p class="terms-desc">
              5.4.1
              尽管有前述安全措施，但同时也请用户理解在信息网络上不存在“完善的安全措施”。
              如不幸发生用户信息安全事件后，我们将按照法律法规的要求向用户告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、用户可自主防范和降低风险的建议、对用户的补救措施等。事件相关情况我们将以电话、推送通知等方式告知用户，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
            </p>

            <p class="terms-desc">5.5 我们如何保存用户信息</p>

            <p class="terms-desc">
              5.5.1存储时限
              您在使用昶联期间，我们将持续为用户保存用户的相关信息，但相关信息将以实现目的所必需的最小化时间进行保存，除非经过用户的同意延长保存期限或受到法律的允许，否则，我们将对用户的个人信息进行源数据及备份数据的删除或匿名化处理。如果用户注销账号或主动删除上述信息，昶联将依据法律法规规定的最短期限保留用户的现有个人信息，在法律要求的最短保存期限内（如为了实现争议解决并满足诉讼时效和举证之需要，或为履行网络在线交易/互联网服务对于身份及交易信息的保存义务，用户提供的个人信息将至少保存3年），我们将不再对用户的个人信息进行商业化使用。当用户的个人信息超出上述保存期限，我们将会对其进行源数据及备份数据的删除或匿名化处理。
            </p>

            <p class="terms-desc"><strong>5.5.2存储地域</strong></p>
            <p class="terms-desc">
              原则上，我们仅收集在中华人民共和国境内产生的个人信息，并且将收集到的个人信息存储在中华人民共和国境内。您应自主地选择使用本服务的范围，并且负责遵守任何适用的法律，包括但不限于任何当地法律。
            </p>

            <p class="terms-desc">
              我们请用户特别注意：不同国家或地区对于个人信息的收集、存储、使用、共享等各有其监管要求，用户应主动遵守各个国家或地区的法律法规和监管要求。为了避免用户由于不了解用户的最终用户所在国或所在地区关于个人信息保护相关法律法规而触犯当地的监管规定，我们强烈建议用户仅在中国境内使用昶联服务。如用户将服务应用于中国境外的，用户应当遵循当地有关个人信息跨境传输转移的法律要求，确保取得您终端用户的单独同意，以保证在中国境内存储个人信息符合当地的监管要求。特别是，当跨境传输涉及俄罗斯、印度、欧盟、美国等国家或地区时，请注意这些国家和地区的个人信息监管规定。用户同意，跨境传输产生的风险和责任将由用户自行承担，如导致我们发生任何形式的损失，用户负责给予我们全额赔偿。
            </p>

            <p class="terms-desc"><strong>六、终端用户的授权与同意</strong></p>

            <p class="terms-desc">
              您作为我们的业务合作伙伴十分清楚，为给您和/或您的终端用户提供您所选择的昶联服务，我们会以去标识化或匿名化方式收集、存储、加工来自于您的应用程序的终端用户的个人信息。因此，您接受本政策并把我们的SDK、API或其他统计分析工具、开发者工具集成到您的产品中，则表示您确认并保证：
            </p>

            <p class="terms-desc">
              6.1
              您已经告知终端用户并且获得终端用户充分、必要且明确的授权、同意和许可，为提供服务之目的允许我们收集和使用其信息。
            </p>

            <p class="terms-desc">
              6.2
              除非适用法律另有规定，您已经告知终端用户并且获得终端用户充分必要的授权、同意和许可，允许我们对已收集的个人信息进行去标识化处理，允许我们在符合相关法律法规的前提下可将收集、处理的信息用于本政策第一条列明的服务用途。
            </p>

            <p class="terms-desc">
              6.3
              您已经遵守并将持续遵守适用的法律、法规和监管要求，包括但不限于制定和公布有关个人信息保护和隐私保护的相关政策。
            </p>

            <p class="terms-desc">
              6.4
              您已经向终端用户提供易于操作的用户权利实现机制，说明用户如何以及何时可以行使选择权、如何访问、更正其个人信息、行使删除权、更改其授权同意的范围等操作。
            </p>

            <p class="terms-desc"><strong>七、未成年人隐私保护</strong></p>

            <p class="terms-desc">
              7.1
              我们不接受儿童注册登记成为我们的用户，并且我们原则上也不接受用户提供14周岁以下终端用户的个人信息，请用户注意并谨慎提供。尽管各地法律和习俗对儿童的定义不同，但我们将不满
              14 周岁的任何人均视为儿童。如果我们在不知情的情况下向不满 14
              周岁的儿童收集了个人信息，我们将及时删除相关资料，除非法律要求我们保留此类资料。
            </p>

            <p class="terms-desc">
              7.2如果您认为我们错误或意外地向不满14周岁的儿童收集了信息，请及时联系我们：<a
                href="javascript:;"
                >legal@solutioncl.com。</a>
            </p>

            <p class="terms-desc">
              7.3如果用户的应用是针对不满14周岁的儿童而设计和开发的，请务必确保您的终端用户为监护人，已经阅读并同意应用程序的隐私政策，授权同意提供儿童个人信息给我们以实现应用的相关功能。
            </p>

            <p class="terms-desc"><strong>八、本协议如何更新</strong></p>

            <p class="terms-desc">
              昶联平台保留不时更新或修改本政策的权利。如果昶联平台对隐私政策声明做出变更或修订，昶联平台可能会通过不同渠道向用户发送变更通知，例如，通过电子邮件或者其他方式向用户发布通知。<strong>我们鼓励用户随时查看本隐私政策来了解最新的变化。</strong>如用户继续使用我们的服务，表示同意接受修订后的本协议的内容，如用户不同意经修订的本政策，我们将无法继续为用户提供服务。
            </p>
            <strong>
            <p class="terms-desc">九、本隐私政策适用范围</p>

            <p class="terms-desc">
              9.1
              如果您在昶联网站上注册账户或购买昶联产品与服务的，本政策将予以适用，除非相关服务已有独立的隐私权政策或相应的用户协议当中存在特殊约定。
            </p>

            <p class="terms-desc">
              9.2
              本隐私政策不适用于昶联平台服务中链接到其他第三方的产品或服务，用户使用这些第三方服务（包括用户向这些第三方提供的任何个人信息），将受这些第三方的服务条款及隐私政策约束（而非本隐私政策），具体规定请用户仔细阅读第三方的条款。请用户妥善保护信息，仅在必要的情况下向第三方提供。
            </p>

            <p class="terms-desc">
              9.3
              需要特别说明的是，作为昶联的用户，我们也建议您利用我们云服务而再向您用户提供服务，涉及收集和使用您的用户个人信息的，应当另行与您的用户约定隐私权政策。
            </p>

            <p class="terms-desc">9.4处理个人信息的其他法律依据（仅限EEA）</p>

            <p class="terms-desc">
              我们请用户特别注意：当用户进行个人信息的处理活动，如果用户符合以下任何一项条件，请用户注意遵守《欧盟通用数据保护条例》（General
              Data Protection Regulation，以下简称“GDPR”）：
            </p>

            <p class="terms-desc">
              （1）用户在欧盟经济区域（“EEA”），无论该处理活动是否在欧盟进行；
            </p>
            <p class="terms-desc">
              （2）向在EEA的个人提供商品或服务（不论是否有偿），或者监视其在EEA内的行为；
            </p>
            <p class="terms-desc">
              （3）不在EEA内，但根据国际公法适用欧盟成员国法律（例如EEA成员国的使馆或领事馆）。
            </p>
            </strong>
            <p class="terms-desc">
              如果您和/或您的终端用户来自EEA，我们收集和使用上述个人信息的法律依据将取决于相关个人信息以及我们收集信息的具体背景。
            </p>

            <p class="terms-desc">
              但是，我们通常只在我们需要个人信息来与用户履行合同的情况下，或在处理符合我们的合法利益且不被用户的数据保护权益或基本权利和自由所覆盖的情况下，或在我们获得用户同意的情况下这样做。在某些情况下，我们还可能有法律义务向您和/或您的终端用户收集个人信息，或者可能需要个人信息来保护用户或其他人的切身利益，例如，出于维护公共安全的目的，在相关部门的要求下提供用户的个人信息，或对执法部门的要求作出回应。
            </p>

            <p class="terms-desc">
              如果用户对我们收集和使用您和/或您的终端用户的个人信息的法律依据有疑问或需要进一步的信息，请使用本隐私政策第十部分提供的联系信息与我们取得联系。
            </p>
            <strong>
            <p class="terms-desc">十、联系我们</p>

            <p class="terms-desc">
              10.1
              如果用户对本隐私政策有任何疑问、意见或建议，可以通过下述方式联系我们：
            </p>
            <p class="terms-desc">邮箱：<a
                href="javascript:;"
                >legal@solutioncl.com；</a></p>
            <p class="terms-desc">电话：<a
                href="javascript:;"
                >400-7800-900；</a></p>
            <p class="terms-desc">
              邮寄地址：浙江省宁波市鄞州区邱隘镇沈家村晨源大楼8-02-146室收。
            </p>

            <p class="terms-desc">
              10.2
              为保障我们高效处理用户的问题并及时向您反馈，需要用户提交公司资质、有效联系方式和书面请求及相关证据，我们会在验证用户的真实身份后处理用户的请求。如果用户对我们的回复不满意，特别是当我们的个人信息处理行为损害了用户的合法权益时，用户还可以通过向宁波市鄞州区人民法院提起诉讼进行解决。您也可以向我们了解可能适用的相关投诉途径的信息。
            </p>

            <p class="terms-desc">
              10.3
              对于EEA中的个人，用户有权根据适用的数据保护法律向主管数据保护机构投诉或向具有管辖权的法院提起诉讼。
            </p>

            <p class="terms-desc">最近更新时间：2024年8月 1日</p>
            </strong>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
  
<script>
export default {
  name: "ZhPrivacyPolicy",
};
</script>
<style scoped>
.center-align {
  display: block;
  text-align: center;
}
:deep(strong > p) {
  font-weight: bold !important;
}
.indent {
  text-indent: 2ch;
}
.right-align {
  display: block;
  text-align: right;
}
.article-Registration {
  padding-left: 24px;
}
.article {
  list-style-type: inherit;
  margin-left: 8px;
}
.no-list-style {
  list-style-type: none;
}
.terms .article li {
  font-weight: 400;
}
.li-img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>